<form [formGroup]="questionForm">
    <div formArrayName="options">
        <div *ngFor="let eachOption of checkboxAnswerFA().controls; let i = index"
            class="add__question--input--container">
            <div [formGroupName]="i" class="add__question--left--content answer__content--input">
                <div class="checkbox__with__answer">
                    <acengage-checkbox-input [ngClass]="{'custom_rounded_checkbox': isMultiChoice}" [disabled]="true">
                    </acengage-checkbox-input>
                    <div class="input__container">
                        <acengage-text-input [maxLength]="null" (ngModelChange)="questionForm.updateValueAndValidity();"
                            formControlName="choice" [placeholder]="'Option '+(i+1)+' *'" [isShowFloatLabel]="false">
                        </acengage-text-input>
                        <acengage-form-error [control]="eachOption.controls.choice" validatorName="required">
                            Option {{i+1}} is required.
                        </acengage-form-error>
                    </div>
                </div>
                <!-- reason questions -->
                <div class="follow--questions" *ngIf="!isMultiChoice">
                    <div class="add__question--link add" (click)="addFollowUp.emit(i);" *ngIf="!eachOption.get('nested_question')?.value?.options?.length">
                        <i class="pi pi-plus"></i>
                        <span>Add Reason Question</span>
                    </div>
                    <div class="add__question--link add" *ngIf="eachOption.get('nested_question')?.value?.options?.length">
                        <i class="pi pi-plus"></i>
                        <span>Add Reason Question</span>
                    </div>
                    <div class="follow--questions__section" *ngIf="eachOption.get('nested_question')?.value?.options?.length">
                        <div class="follow--questions__head">
                            <p class="follow-p">Reason question</p>
                            <div>
                                <div class="add__question--link remove" (click)="removeFollowup.emit(i);">
                                    <i class="pi pi-trash"></i>
                                    <span>Remove</span>
                                </div>
                            </div>
                        </div>
                        <div class="follow--questions__add">
                            <acengage-nested-choice-question [nestIndex]="i" [nestedQuestions]="questionForm" (updateNestedQuestions)="updateNestedQuestion(i, $event)"></acengage-nested-choice-question>
                        </div>
                    </div>
                </div> 
                  <!-- follow up questions -->
                  <div class="follow--questions" *ngIf="!isMultiChoice">
                    <div class="add__question--link add" (click)="addQuestion.emit(i);" *ngIf="!eachOption.get('followup_question')?.value">
                        <i class="pi pi-plus"></i>
                        <span>Add Follow up Question</span>
                    </div>
                    <div class="add__question--link add" *ngIf="eachOption.get('followup_question')?.value">
                        <i class="pi pi-plus"></i>
                        <span>Add Follow up Question</span>
                    </div>
                    <div class="follow--questions__section" *ngIf="eachOption.get('followup_question')">
                        <div class="follow--questions__head">
                            <p class="follow-p">Follow up question</p>
                            <div>
                                <div class="add__question--link remove" (click)="removeQuestion.emit(i);">
                                    <i class="pi pi-trash"></i>
                                    <span>Remove</span>
                                </div>
                            </div>
                        </div>
                        <div class="follow--questions__add">
                            <acengage-nested-followup-question [nestIndex]="i" [nestedQuestions]="questionForm" (updateNestedQuestions)="updateFollowupQuestion(i, $event)"></acengage-nested-followup-question>
                            <!-- <acengage-text-input [inputType]="'text'" [maxLength]="null" [placeholder]="'Enter the question here'" formControlName="followup_question"></acengage-text-input> -->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="checkboxAnswerFA().controls.length > 2" class="add__question--right--content">
                <div class="add__question--link remove" (click)="removeClicked.emit(i);">
                    <i class="pi pi-trash"></i>
                    <span>Remove</span>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="add__question--link add" (click)="addClicked.emit();">
    <i class="pi pi-plus"></i>
    <span>Add Options</span>
</div>
import { Component, forwardRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { throws } from 'assert';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi: true,
    },
  ],
})
export class CheckboxInputComponent implements ControlValueAccessor {
  optionlist:any=[]
  @Input() set options(value:any){
    this.optionlist=value;
    this.optionlist.forEach((obj:any)=>{
      obj['valueSelection']='';
    })
    console.log('gggg',this.optionlist)
  };
  @Input() cbLabel: string = '';
  @Input() classesToAdd: string = '';
  @Input() isDateInput: boolean = false;
  @Input() disabled: boolean = false;
  @Input() followUp: boolean = false;
  @Input() isNeedOutput: boolean = false;
  @Input() showVeritically: boolean = false;
  @Input() behaveLikeRadio: boolean = false;
  @Output() checkboxClicked = new EventEmitter<boolean>();
  private valueChanges = new Subject<string>();
  private touches = new Subject();
  value: any;
  onChange = () => { };
  onTouch = () => { };
  ngOnInit(): void {
    console.log('lllllllllll',this.optionlist)
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value;
  }
  clearCheck(value: any){
    if(this.behaveLikeRadio){
      this.optionlist.forEach((obj:any)=>{
        if(obj.value != this.value){
          obj['valueSelection']=false;
        }
    })
    }

  }
  updateValue(value: any) {
    this.value = value;
    if (this.isNeedOutput) {
      this.checkboxClicked.emit(value);
    }
    this.valueChanges.next(value);
  }
  touch() {
    this.touches.next(null);
  }
}

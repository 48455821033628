import { Component, OnInit, forwardRef, Injector, Input, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorService } from '../../shared-service/valueAccessor.service';

@Component({
  selector: 'acengage-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingComponent),
      multi: true,
    },
  ],
})
export class RatingComponent extends ValueAccessorService implements OnInit {
  @Input() noOfStars: number = 5;
  @Input() starCount: number = 4;
  @Input() readonly: boolean = false;
  @Input() override disabled: boolean = false;
  @Output() onRate :EventEmitter<number>=new EventEmitter()
  constructor(injector: Injector) {
    super();
  }

  ngOnInit(): void { }
  ratingValue(event:any){
    this.onRate.emit(event)
  }
}

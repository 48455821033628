<p-dialog [styleClass]="classesToAdd" [closable]="closable" [header]="headerText" [(visible)]="displayModal"
    [modal]="true" [closable]="addCloseIcon" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" (onHide)="onHide($event)" [resizable]="false" [ngClass]="{'new-width':nestQuestions}">
    <ng-content></ng-content>
    <div *ngIf="addBorderAboveBtns" class="line"></div>
    <ng-template *ngIf="showFooterButtons" pTemplate="footer">
        <p-button [styleClass]="classesToAdd" [ngClass]="{'cancelCss':cancelButtonText==='Cancel'}" [icon]="cancelButtonText === 'Ok' ? '' : 'pi pi-times'" *ngIf="cancelButtonText && !nestQuestions"
            (click)="cancelButtonClicked()" [label]="cancelButtonText"></p-button>
        <p-button [icon]="skipButtonText === 'skip' ? '' : 'pi pi-arrow-right'" *ngIf="skipButtonText && !nestQuestions"
            (click)="cancelButtonClicked()" [label]="skipButtonText"></p-button>
        <p-button [icon]="cancelButtonText === 'Continue' ?'pi pi-arrow-right':'pi pi-check'" *ngIf="confirmButtonText" [disabled]="buttonDisable" (click)="confirmButtonClicked()"
            [label]="confirmButtonText" class="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>

import { Component, OnInit,forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers:[{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchInputComponent),
    multi: true,
  }]
})
export class SearchInputComponent implements ControlValueAccessor {
  constructor() {}

  value: any;
  disabled = false;
  private valueChanges = new Subject<string>();
  private touches = new Subject();

  registerOnChange(fn: any) {
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value;
  }
  updateValue(value: any) {
    this.value = value;
    this.valueChanges.next(value);
  }
  touch() {
    this.touches.next(null);
  }
}

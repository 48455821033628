import { Component, OnInit, forwardRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorService } from '../../shared-service/valueAccessor.service';

@Component({
  selector: 'acengage-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeSliderComponent),
      multi: true,
    },
  ],
})
export class RangeSliderComponent extends ValueAccessorService implements OnInit {

  @Output() onSlider: EventEmitter<number> = new EventEmitter()

  rangeValue = [0,10]
  @Input() set rangeValues(value: any) {
    this.rangeValue = value;
  }
  @Input() minRange = 1;
  @Input() maxRange = 10;
  @Input() animate = true;
  @Input() view = false;
  @Input() override disabled = false;
  @Input() orientation = 'horizontal';
  @Input() allowTwoBoundary = true;
  @Input() classesToAdd = '';
  @Input() step = 1;
  selectedValue = 0;
  constructor(injector: Injector) {
    super();
  }

  ngOnInit(): void { }
  sliderValue(event: any) {
    this.selectedValue = event ? event : 0
    this.onSlider.emit(event)
  }
}

<div class="btn__container">
    <ng-container *ngFor="let eachButtonDtl of buttonDetails">
        <div *ngIf="!eachButtonDtl.hideBtn && eachButtonDtl.isFlatBtn"
            [class]="'flat__btn--link '+eachButtonDtl.buttonClassesToAdd"
            (click)="buttonClicked.emit(eachButtonDtl.buttonText ? eachButtonDtl.buttonText : eachButtonDtl.iconDetails?.name);">
            {{eachButtonDtl.buttonText}}</div>
        <acengage-button [buttonText]=" eachButtonDtl.buttonText"
            *ngIf="!eachButtonDtl.hideBtn && !eachButtonDtl.isFlatBtn"
            [buttonClassesToAdd]="eachButtonDtl.buttonClassesToAdd" [iconDtls]="eachButtonDtl.iconDetails"
            [isBtnDisable]="eachButtonDtl.enableAlways ? !eachButtonDtl.enableAlways : (eachButtonDtl.disabled || disableBtn || (eachButtonDtl.isCheckSelectedRow && selectedRows.length === 0))"
            [isNgContentAdded]="eachButtonDtl?.isNgContentAdded"
            (buttonClicked)="buttonClicked.emit(eachButtonDtl.buttonText ? eachButtonDtl.buttonText : eachButtonDtl.iconDetails?.name);">
        </acengage-button>
    </ng-container>
</div>
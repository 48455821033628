import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorService } from '../../shared-service/valueAccessor.service';

@Component({
  selector: 'acengage-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectButtonComponent),
      multi: true,
    },
  ],
})
export class SelectButtonComponent extends ValueAccessorService {
  @Input() options: Array<any> = [];
  @Input() optionLabel = 'name';
  @Input() optionValue = 'value';
  @Input() isMultiSelect = false;
  @Input() override disabled = false;
  @Input() appendTo: string | null = null;
  @Input() classesToAdd = '';
  @Input() name = '';

}

import { headers } from './../../../../../apps/acengage/src/app/admin-dashboard/models/admin-dashboard-response.interface';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { FormControl } from '@angular/forms';
import { TableHeader } from '../../models/table.model';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { TypeOfJobs } from 'apps/acengage/src/app/models/data';
import { SelectOption } from 'apps/acengage/src/app/models/select.model';
import moment from 'moment';
import { PermissionList } from '@acengage/app/app/state/app.model';
import { CommonButtonInput } from '@acengage/ui/models/button.model';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { Store } from '@ngrx/store';

interface Country {
  name?: string;
  code?: string;
}

interface Representative {
  name?: string;
  image?: string;
}

interface Customer {
  id?: number;
  name?: number;
  country?: Country;
  company?: string;
  date?: string;
  status?: string;
  representative?: Representative;
}

@Component({
  selector: 'acengage-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit {
  constructor(private primengConfig: PrimeNGConfig, private store: Store) {}
  @Output() uploadedDoc = new EventEmitter();
  @Output() downloadDoc = new EventEmitter<any>();
  @Output() removeOrientation = new EventEmitter<any>();
  @Output() tataDialer = new EventEmitter<any>();
  @Output() audioDownload = new EventEmitter<any>();
  @Output() typeClick = new EventEmitter<any>();
  @Input() actionItems: MenuItem[] = [];
  @Input() detailPage = false;
  @Input() showCampaignDetails = false;
  @Input() headerWidth80 = false;
  @Input() multipleRowsSelected = false;
  @Input() showContractId = true;
  @Input() isIndividualCellEditable = false;
  @Input() tdRowClass = 'p-selectable-row';
  @Input() tableFooterText = '';
  @Input() showSearchMsg:boolean = false;
  searchFilterControl = new FormControl('');
  @Input() set preservedFilterDtls(value: any) {
    this.preFilteredDtls = value ? JSON.parse(JSON.stringify(value)) : {};
    this.searchFilterControl.setValue(this.preFilteredDtls?.search || '');
  }
  @Input() iscardClick: boolean = false;
  @Output() deleteRecordEvent = new EventEmitter<any>();
  @Output() triggerEmployeeEmailEvent = new EventEmitter<any>();
  preFilteredDtls: any = {};
  campaignTableDetails = {
    campaignDetails: {
      campaignName: '',
      contractName: '',
      confidentiality: '',
      campaignId: '',
      campaignStatus: '',
    },
  };
  processDetails = {
    inprogress: 0,
    completed: 0,
    exited:0,
    ncstatus: 0,
    pending: 0,
  };
  @Input() set processDetailCount(value: any) {
    if (value != undefined) {
      this.processDetails = {
        inprogress: value.inprogress != null ? value.inprogress : 0,
        completed: value.completed != null ? value.completed : 0,
        exited: value.exited != null ? value.exited : 0,
        ncstatus: value.ncstatus != null ? value.ncstatus : 0,
        pending: value.pending != null ? value.pending : 0,
      };
    }
  }
  @Input() set campaignDetailsForTable(value: any) {
    if (value != undefined) {
      this.campaignTableDetails = {
        campaignDetails: {
          campaignName:
            value.campaignDetails.campaignName != null
              ? value.campaignDetails.campaignName
              : '',
          contractName:
            value.campaignDetails.contractName != null
              ? value.campaignDetails.contractName
              : '',
          confidentiality:
            value.campaignDetails.confidentiality != null
              ? value.campaignDetails.confidentiality
              : '',
          campaignId:
            value.campaignDetails.campaignId != null
              ? value.campaignDetails.campaignId
              : '',
          campaignStatus:
            value.campaignDetails.campaignStatus != null
              ? value.campaignDetails.campaignStatus
              : '',
        },
      };
    }
  }
  filterFields = [];
  @Input() set filterFieldValues(value: any) {
    this.filterFields = value;
  }
  records: any[];
  updatedrecords: [];
  counsellorAssign: [];
  representatives: Representative[];
  @Input() ischeckBox = true;
  @Input() isTag = false;
  isSort = true;
  isActions = true;
  totalCount = 200;
  headers: TableHeader[] = [
    {
      headerName: 'Name',
      objectKeyName: 'name',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Country',
      objectKeyName: 'country',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Representative',
      objectKeyName: 'representative',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Date',
      objectKeyName: 'date',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Status',
      objectKeyName: 'status',
      isSortable: false,
      isShow: true,
      customColorName: 'var(--teal-500)',
    },
  ];
  skipRows = 0;
  sortColumnName = '';
  sortOrder = 1;
  leadTypeModel: string;
  @Input() rows = 30;
  @Input() set page(value: number) {
    this.skipRows = ((value ? Number(value) : 1) - 1) * this.rows;
  }
  @Input() set sortValue(value: string) {
    if (value) {
      this.sortOrder = value.includes('-') ? -1 : 1;
      const sortName: string = value.replace('-', '');
      this.sortColumnName =
        this.headers.find((eachHeader) => {
          return (
            eachHeader.objectKeyName === sortName ||
            eachHeader.sortKey === sortName
          );
        })?.objectKeyName || '';
    } else {
      if (this.myTable) {
        this.myTable.reset();
      }
    }
  }
  @Input() isShowPagination = true;
  @Input() currentModule = '';
  @Input() ServiceModule = '';
  @Input() ServiceType = '';
  @Input() selectedRows: Array<any> = [];
  @Input() selectBtnOptions: SelectOption[] = [];
  @Input() showStripedRows = true;
  @Input() isShowEditColumns = false;
  @Input() isShowSearch = true;
  @Input() searchPlaceholder = 'Search keyword';
  @Input() searchAllowedKeys: RegExp | string;
  @Input() permissionRole: PermissionList = {
    feature_name: '',
    uid: '',
    role: '',
    add: false,
    edit: false,
    delete: false,
    view: false,
    export: false,
    nc_weekly_update: false,
  };
  @Input() viewTable = false;
  @Input() campaignStatus = false;
  @Input() isShowExportBtn = false;
  @Input() buttonEnableByDefault = false;
  @Input() isShowExportIcon = true;
  @Input() customButtonText = 'Export';
  @Input() selectedData = '';
  @Input() isShowActions = false;
  @Input() isShowFilter = true;
  @Input() buttonClassesToAdd = 'p-button-outlined p-button-secondary';
  @Input() buttonDetails: CommonButtonInput[] = [];
  @Input() isCheckSelectedRowActions = true;
  @Input() maxColumnLimit = 0;

  @Input()
  set headerlabels(value: TableHeader[]) {
    this.headers = value;
  }
  @Input()
  set tableRecords(value: any) {
    this.records = value;
  }
  @Input()
  set checkboxes(value: any) {
    this.ischeckBox = value;
  }
  @Input()
  set sort(value: any) {
    this.isSort = value;
  }
  @Input()
  set total(value: any) {
    this.totalCount = value;
  }
  @Input()
  set tableLoader(value: boolean) {
    this.loading = value;
  }

  @Input()
  set showActions(value: boolean) {
    this.isActions = value;
  }
  dateType: any = [
    { value: 'doj', name: 'Date of Joining' },
    { value: 'interventiondate', name: 'Intervention Date' },
  ];

  filterDisable = true;
  @Output() pageChange = new EventEmitter();
  @Output() rowSelection = new EventEmitter();
  @Output() countsPerPageChange = new EventEmitter();
  @Output() sortTriggered = new EventEmitter<string>();
  @Output() searchTriggered = new EventEmitter<string>();
  @Output() exportTriggered = new EventEmitter<any>();
  @Output() actionTriggered = new EventEmitter<any>();
  @Output() isAction = new EventEmitter<any>();
  @Output() filterTriggered = new EventEmitter<any>();
  @Output() selectedConnect = new EventEmitter<any>();
  @Output() dataActionTriggered = new EventEmitter<any>();
  @Output() customBtnTriggered = new EventEmitter<any>();
  @Output() individualCellValueChanges = new EventEmitter<any>();
  @Output() headersDtl = new EventEmitter<any>();
  @Input() isCheckBoxSelection = false;
  @Output() checkBoxClicked = new EventEmitter<any>();
  @Output() validationTriggered = new EventEmitter<string>();
  @Input() DateFormat:boolean = true;
  statuses: any = [];
  loading = false;
  selectedSortCol: string;
  filteredData: any = {
    search: '',
  };
  typeOfJobsList: Array<SelectOption> = TypeOfJobs;
  buddyInductionStatusList = [
    { value: 0, name: 'Not Attempted', status: false },
    { value: 1, name: 'RNR', status: false },
    { value: 2, name: 'Exited', status: false },
    { value: 3, name: 'Scheduled', status: true },
    { value: 4, name: 'Completed', status: true },
  ];
  @Input() set removeSelection(value: any) {
    if (value) {
      // this.selectedRows[]
      this.selectedRows = [];
      this.myTable.reset();
    }
  }
  @ViewChild('myTable') myTable: Table;
  @Input() setPermissionForActionButton = false;
  @Input() enableSearchTriggered = false;
  excludedUserRoles: any[] = [11, 12, 13];
  disableActionButton = false;

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.searchFilterControl.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe((value: any) => {
        this.myTable.reset();
        this.filteredData.search = value;
        if (this.enableSearchTriggered && value) {
          this.searchTriggered.emit(this.filteredData);
        } else {
          this.filterTriggered.emit(this.filteredData);
        }
      });
    this.setActionPermissionRoles();
  }

  getJobTypeName(value: number | null) {
    return (
      this.typeOfJobsList.find((eachJobType) => eachJobType.value == value)
        ?.name || ''
    );
  }

  getConfidentiality(value: string) {
    if (value == 'Yes') {
      return 'Confidential';
    } else if (value == 'No') {
      return 'Non-confidential';
    } else if (value) {
      return value;
    } else {
      return '-';
    }
  }
  selectConnect(data: any) {
    this.filteredData.status = data;
    this.selectedConnect.emit(data);
  }
  formatDate(date: any) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  saveColumnChanges(changedColumns: TableHeader[]) {
    const showableColumns = changedColumns.filter((eachCol) => eachCol.isShow);
    if (!this.maxColumnLimit || showableColumns.length <= this.maxColumnLimit) {
      this.headers = [...changedColumns];
      this.headersDtl.emit(this.headers);
    } else {
      this.validationTriggered.emit(
        `You have selected ${showableColumns.length} columns, please choose ${this.maxColumnLimit} columns or fewer.`
      );
    }
  }

  onRepresentativeChange(event: any) {
    this.myTable.filter(event.value, 'representative', 'in');
  }
  pageChanged(event: any) {
    const pagination = event;
    pagination['page'] = this.calculatePage(event.first, event.rows);
    this.pageChange.emit(pagination);
    this.myTable.scrollTo({ left: 0 });
    this.myTable.scrollTo({ top: 0 });
  }
  applySort(colName: string) {
    if (colName == this.selectedSortCol) {
      this.selectedSortCol = '-' + colName;
    } else {
      this.selectedSortCol = colName;
    }
    this.sortTriggered.emit(this.selectedSortCol);
  }
  applyExport(event: any) {
    this.exportTriggered.emit(this.selectedRows);
  }
  calculatePage(first: any, rowsPerpage: any) {
    if (first == 0) return 1;
    else if (first / rowsPerpage >= 1) {
      return first / rowsPerpage + 1;
    } else {
      return 1;
    }
  }
  toLocalDate(date: Date, dateFormat: string | undefined) {
    if(!date)return;
    const format = dateFormat? dateFormat : 'DD/MM/YYYY'
    return moment
      .utc(date)
      .local()
      .format(format);
  }

  onRowSelect(event: any) {
    if (this.isCheckBoxSelection) {
      this.checkBoxClicked.emit(event.data);
    }
    if (event?.type === 'checkbox') {
      const selectedRowIndex = this.records.findIndex(
        (eachData: any) => eachData.id === event?.data?.id
      );
      this.records[selectedRowIndex].isRowChecked = true;
    }
  }

  onRowUnselect(event: any) {
    if (event?.type === 'checkbox') {
      const selectedRowIndex = this.records.findIndex(
        (eachData: any) => eachData.id === event?.data?.id
      );
      this.records[selectedRowIndex].isRowChecked = false;
    }
  }

  showToaster(type: string, heading: string, message: string,isHide?:boolean) {
    this.store.dispatch(
      AppActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: isHide?false:true,
      })
    );
  }

  fileUploads(event?: any, data?: any) {
    if (event[0] && data) {
      const formData = new FormData();
      const pass = {
        name: event[0]?.name,
        service_type_id: data.service_type_id,
        type: data.id,
        size: event[0]?.size,
        file_type: event[0]?.type,
      };
      formData.set('basics_details', JSON.stringify(pass));
      formData.set('file', event[0]);
      this.uploadedDoc.emit(formData);
    }
  }

  onSelectAllRow(event: any) {
    this.records = this.records.map((eachRecord) => {
      eachRecord.isRowChecked =
        (event?.checked && !eachRecord.isDisableCheckbox) || false;
      return eachRecord;
    });
    setTimeout(() => {
      this.selectedRows = [
        ...this.records.filter((eachRecord) => eachRecord.isRowChecked),
      ];
    });
  }

  getCounsellorAssigned(event: any) {
    const data = event.split(',');
    this.counsellorAssign = data;
    const value: string[] = [];
    data.map((res: string, i: number) => {
      if (i < 3) {
        value.push(res);
      }
    });
    return value;
  }
  isActionView(event: any) {
    this.isAction.emit(event);
  }
  updateTheFilterSelection(event: any) {
    this.myTable.reset();
    this.filteredData = { ...this.filteredData, ...event };
    this.filterTriggered.emit(this.filteredData);
    this.updateFilterDisable(event);
    this.myTable.scrollTo({ left: 0 });
  }
  customBtnClicked(clickedBtnLabel: string) {
    const formBtnClickEvt = {
      buttonText: clickedBtnLabel,
      selectedRows: this.selectedRows,
    };
    this.customBtnTriggered.emit(formBtnClickEvt);
  }
  updateFilterDisable(event: any) {
    if (
      !event?.created_at?.length &&
      !event?.is_active?.length &&
      !event?.updated_at?.length &&
      !event?.is_active?.length &&
      !event?.reporting_manager__id__in?.length &&
      !event?.role__id?.length &&
      !event?.skip_level_manager__id__in?.length &&
      !event?.user_created_by?.length &&
      !event?.user_modified_by?.length
    ) {
      this.filterDisable = true;
    } else {
      this.filterDisable = false;
    }
  }
  doNothing(e: any) {
    e.stopPropagation();
    return;
  }

  checkDisableCdn() {
    return this.records?.every((eachData: any) => eachData.isDisableCheckbox);
  }
  dateTypeSelection(selectedValue: any) {
    // this.dateTypeModel = selectedValue;
    // console.log(selectedValue);
    // this.dateTypeChange.emit(selectedValue);
    // this.prepareFilter();
  }

  sendValueKey(
    value: any,
    key: string,
    index: number,
    minLength?: number | null
  ) {
    const individualCellUpdateDetails: any = {
      candidate: index,
      minLength,
      value,
    };
    individualCellUpdateDetails[key] = value;
    this.individualCellValueChanges.emit(individualCellUpdateDetails);
  }

  deleteOrientation(data: any) {
  }

  setActionPermissionRoles(): any {
    if (this.setPermissionForActionButton)
      for (let role of JSON.parse(localStorage.getItem('login') as any)[
        'roles'
      ]) {
        if (this.excludedUserRoles.includes(role.id))
          this.disableActionButton = true;
        return;
      }
  }

  tutorialAction(item: any, action: any) {
    this.typeClick.emit({
      action, item
    });
  }

  deleteRecord(record: any) {
    this.deleteRecordEvent.emit(record);
  }

  triggerEmployeeEmail(record: any) {
    this.triggerEmployeeEmailEvent.emit(record);
  }

  getBackgroundColor(customer: any): string {
    if (this.ServiceType != "CE") {
      if (
        !customer['intervention']?.['actual_report_submission_date'] &&
        new Date(customer['intervention']?.['report_submission_date']).getTime() <
        new Date().getTime() - 3 * 24 * 60 * 60 * 1000
      ) {
        return '#EFDB0D';
      }
    }
    if (customer['activity_status'] === 'Call in progress') return 'var(--green-700)';
    if (customer['activity_status'] === 'Start Quality Check Status') return 'var(--yellow-700)';
    if (customer['activity_status'] === 'Generate Internal Report Status') return 'var(--teal-800)';
    if (customer['activity_status'] === 'Send Final Report Status') return 'var(--orange-600)';
  
    if (customer.hasOwnProperty('color_code') && customer['color_code'] !== null) {
      return customer['color_code'];
    }
  
    if (customer['latest_status'] === 4) return 'var(--orange-600)';
    if (customer['latest_status'] === 3) return 'var(--green-700)';
    if ([5, 6, 7].includes(customer['latest_status'])) return 'var(--yellow-700)';
    if ([8, 9, 10].includes(customer['latest_status'])) return '#ffffff';
    
    if (customer['max_nc_count_achieved'] === true) return 'var(--red-500)';
  
    return ''; // Default case
  }
  formatParagraph(value: string){
    return value.replace(/<br\s*\/?>/gi, '\n');
  }
  copyToClipboard(value: string): void {
    const formattedValue = value.replace(/<br\s*\/?>/gi, '\n');

    this.showToaster('', '', "", true);
    navigator.clipboard
      .writeText(formattedValue)
      .then(() => {
        this.showToaster('success', '', "Copied to clipboard successfully!");
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        alert('Unable to copy to clipboard. Please try again.');
      });
  }
  
  
  
}

<div class="headerWithRightButton">
    <div class="header-label">{{headerlabel}}</div>
    <div class="button-section" *ngIf="showBtn">
        <acengage-button [isNgContentAdded]="true" (buttonClicked)="buttonClick.emit()" [buttonText]="buttonlabel">
            <ng-content></ng-content>
        </acengage-button>
    </div>
    <div class="button-section1" *ngIf="showBackBtn">
        <ng-container *ngFor="let eachButtonDtl of buttonDetails">
            <div *ngIf="!eachButtonDtl.hideBtn && eachButtonDtl.isFlatBtn"
                [class]="'flat__btn--link '+eachButtonDtl.buttonClassesToAdd"
                (click)="buttonClicked.emit(eachButtonDtl.buttonText ? eachButtonDtl.buttonText : eachButtonDtl.iconDetails?.name);">
                {{eachButtonDtl.buttonText}}</div>
            <acengage-button [buttonText]=" eachButtonDtl.buttonText"
                *ngIf="!eachButtonDtl.hideBtn && !eachButtonDtl.isFlatBtn"
                [buttonClassesToAdd]="eachButtonDtl.buttonClassesToAdd" [iconDtls]="eachButtonDtl.iconDetails"
                [isBtnDisable]="eachButtonDtl.disabled" [isNgContentAdded]="eachButtonDtl?.isNgContentAdded"
                (buttonClicked)="buttonClicked.emit(eachButtonDtl.buttonText ? eachButtonDtl.buttonText : eachButtonDtl.iconDetails?.name);">
                <ng-content></ng-content>
            </acengage-button>
        </ng-container>
    </div>
</div>

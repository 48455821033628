import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../core/services';
import * as authActions from '../authentication/state/auth.actions';


@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(public router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loggedInDtls = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login') || '{}') : {};
    if (loggedInDtls &&
      loggedInDtls.roles &&
      loggedInDtls.roles[0] && loggedInDtls.roles[0].id === 11 && !loggedInDtls.is_client_2fa_verified && loggedInDtls.is_2fa) {
      let queryParamKeyVal = {};
      if (!state.url?.includes('dashboard')) {
        queryParamKeyVal = { redirectUrl: state.url };
      }
      this.router.navigate(['auth', 'login'], { queryParams: queryParamKeyVal });
      return false;
    } else if (loggedInDtls.token) {
      const redirectUrl: string = this.activatedRoute.snapshot.queryParams['redirectUrl'] || '';
      const urlToGetValue: string = redirectUrl ? redirectUrl : state.url; 
      // const selectMenuDtls: { menuIndex: number, submenuIndex: number } = this.authService.getMenuAndSubmenuIndex(urlToGetValue, loggedInDtls.menuItem,loggedInDtls.roles[0].id);
      const selectMenuDtls: { menuIndex: number, submenuIndex: number } = this.authService.getMenuAndSubmenuIndex(urlToGetValue, loggedInDtls.menuItem);
      this.store.dispatch(authActions.selectedMenu({ selectedMenu: loggedInDtls?.menuItem[selectMenuDtls.menuIndex]?.main_menu_id || 1, selectedSubMenu: loggedInDtls?.menuItem[selectMenuDtls.menuIndex]?.sub_menu[selectMenuDtls.submenuIndex]?.sub_menu_id || 0 }));
      return true;
    } else {
      let queryParamKeyVal = {};
      if (!state.url?.includes('dashboard')) {
        queryParamKeyVal = { redirectUrl: state.url };
      }
      this.router.navigate(['auth', 'login'], { queryParams: queryParamKeyVal });
      return false;
    }

    // return this.store.select(getLoginStates).pipe(map(obj => (obj.token && obj.token !== '') === true), tap((isLoggedIn: boolean) => {
    //   if (!isLoggedIn) {
    //     this.router.navigate(['auth', 'login'], { queryParams: { redirectUrl: state.url } });
    //     return false;
    //   } else {
    //     if (this.sideMenuStateSubscription) {
    //       this.sideMenuStateSubscription.unsubscribe();
    //     }
    //     this.store.select(getSideMenuState).subscribe((menuState) => {
    //       const redirectUrl: string = this.activatedRoute.snapshot.queryParams['redirectUrl'] || '';
    //       const urlToGetValue: string = redirectUrl ? redirectUrl : this.router.url;
    //       const selectMenuDtls: { menuIndex: number, submenuIndex: number } = this.authService.getMenuAndSubmenuIndex(urlToGetValue, menuState.menusItem);
    //       this.store.dispatch(authActions.selectedMenu({ selectedMenu: selectMenuDtls.menuIndex, selectedSubMenu: selectMenuDtls.submenuIndex }));
    //     })
    //     return true;
    //   }
    // }))
  }
}
<div
  class="table__edit--container"
  [ngClass]="{
    'min-ht-80vh': isShowEditColumns,
    'min-ht-10re': isShowEditColumns && iscardClick
  }"
>
  <div class="table--container">
    <p-table
      [value]="records"
      [lazy]="true"
      (onPage)="pageChanged($event)"
      dataKey="id"
      #myTable
      [styleClass]="
        'position-static p-datatable-records ' +
        (showStripedRows ? 'p-datatable-striped' : '')
      "
      [first]="skipRows"
      [rowHover]="true"
      [rows]="rows"
      [sortField]="sortColumnName"
      [sortOrder]="sortOrder"
      [totalRecords]="totalCount"
      [showCurrentPageReport]="true"
      [alwaysShowPaginator]="!(totalCount <= 30)"
      [rowsPerPageOptions]="[30, 50, 100]"
      paginatorDropdownAppendTo="body"
      [loading]="loading"
      [paginator]="isShowPagination && totalCount > 0"
      [filterDelay]="10"
      [globalFilterFields]="[
        'name',
        'country.name',
        'representative.name',
        'status'
      ]"
      [(selection)]="selectedRows"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      (onHeaderCheckboxToggle)="onSelectAllRow($event)"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      scrollHeight="calc(100vh - 14rem)"
    >
      <ng-template pTemplate="caption">
        <div
          class="table-header"
          [ngClass]="{ cequery: currentModule === 'CEQuery' }"
        >
          <div
            class="btn__container"
            [ngClass]="{ widthFlex80: headerWidth80 }"
            *ngIf="!detailPage"
          >
            <acengage-filter-table
              *ngIf="isShowFilter"
              [preservedFilterDtls]="preFilteredDtls"
              [filterFields]="filterFields"
              (updatedFilterSelection)="updateTheFilterSelection($event)"
              (clearFilters)="selectedRows = []"
            ></acengage-filter-table>
            <acengage-button
              (buttonClicked)="applyExport($event)"
              [isNgContentAdded]="true"
              [buttonClassesToAdd]="buttonClassesToAdd"
              [buttonText]="customButtonText"
              [isBtnDisable]="
                (!selectedRows.length &&
                  isShowExportBtn &&
                  !buttonEnableByDefault) || !records ||
                records.length === 0
              "
              *ngIf="isShowExportBtn"
            >
              <!-- <span *ngIf="isShowExportIcon"
                ><img
                  src="../../shared-assets/images/general_icons/export_icon_primary.svg"
                  alt="icon"
              /></span> -->
              <img
                  src="../../shared-assets/images/general_icons/export_icon_primary.svg"
                  alt="icon"
              />
            </acengage-button>
            <acengage-table-buttons
              *ngIf="buttonDetails.length"
              [buttonDetails]="buttonDetails"
              [selectedRows]="selectedRows"
              [disableBtn]="!records || records.length === 0"
              (buttonClicked)="customBtnClicked($event)"
            >
            </acengage-table-buttons>
            <acengage-table-actions
              *ngIf="isShowActions"
              [selectedTableRows]="selectedRows"
              [isCheckSelectedRow]="isCheckSelectedRowActions"
              [permissionRole]="permissionRole"
              [actionItems]="actionItems"
              (actionItemClicked)="actionTriggered.emit($event)"
              [disableActionButton]="disableActionButton"
              [multipleRowsSelected]="multipleRowsSelected"
            >
            </acengage-table-actions>
          </div>
          <div class="search-tab">
            <div
              class="search__container"
              [ngClass]="{
                search__container_auto: headerWidth80,
                cequeryTable: currentModule === 'CEQuery'
              }"
              *ngIf="isShowSearch"
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <acengage-text-input
                  [classesToAdd]="'left__icon'"
                  [ngStyle]="{ width: detailPage ? '25rem' : 'auto' }"
                  [allowedKeys]="searchAllowedKeys"
                  [placeholder]="searchPlaceholder"
                  [formControl]="searchFilterControl" 
                  [isShowFloatLabel]="false"
                >
                </acengage-text-input>
              </span>
            </div>
            <div class="tab--view__select" *ngIf="selectBtnOptions.length">
              <acengage-select-button
                [options]="selectBtnOptions"
                [(ngModel)]="selectedData"
                (ngModelChange)="selectConnect($event)"
              ></acengage-select-button>
            </div>
          </div>
        </div>
        <div class="table__campaign__details--container">
          <div
            *ngIf="
              showCampaignDetails &&
              campaignTableDetails?.campaignDetails?.campaignId
            "
            class="displayFlex"
          >
            <span class="margin-right0p2rem nowrap">Campaign ID:</span>
            <span class="white-text name-truncate">
              {{ campaignTableDetails.campaignDetails.campaignId }}</span
            >
          </div>
          <div
            *ngIf="showCampaignDetails && showContractId"
            class="displayFlex"
          >
            <span class="margin-right0p2rem nowrap">Client/Contract Name:</span>
            <span
              class="white-text name-truncate"
              [pTooltip]="campaignTableDetails.campaignDetails.contractName"
              [tooltipDisabled]="
                campaignTableDetails.campaignDetails.contractName.length <= 20
              "
            >
              {{ campaignTableDetails.campaignDetails.contractName }}</span
            >
          </div>
          <div *ngIf="showCampaignDetails && campaignTableDetails.campaignDetails.campaignName.length" class="displayFlex">
            <span class="margin-right0p2rem nowrap">Campaign Name:</span>
            <span
              class="white-text name-truncate"
              [pTooltip]="campaignTableDetails.campaignDetails.campaignName"
              [tooltipDisabled]="
                campaignTableDetails.campaignDetails.campaignName.length <= 20
              "
            >
              {{ campaignTableDetails.campaignDetails.campaignName }}</span
            >
          </div>
          <div
            *ngIf="
              showCampaignDetails &&
              campaignTableDetails.campaignDetails.confidentiality !== ''
            "
            class="displayFlex margin-right0p2rem"
          >
            <span class="margin-right0p2rem nowrap">Confidentiality: </span>
            <span
              class="white-text name-truncate"
              *ngIf="!viewTable"
              [pTooltip]="
                getConfidentiality(
                  campaignTableDetails.campaignDetails.confidentiality
                )
              "
              [tooltipDisabled]="
                getConfidentiality(
                  campaignTableDetails.campaignDetails.confidentiality
                ).length <= 20
              "
            >
              {{
                getConfidentiality(
                  campaignTableDetails.campaignDetails.confidentiality
                )
              }}
            </span>
            <span
              class="white-text name-truncate"
              *ngIf="viewTable"
              [pTooltip]="campaignTableDetails.campaignDetails.confidentiality"
              [tooltipDisabled]="
                campaignTableDetails.campaignDetails.confidentiality.length <=
                20
              "
            >
              {{ campaignTableDetails.campaignDetails.confidentiality }}
            </span>
          </div>
          <div
            *ngIf="
              showCampaignDetails &&
              campaignTableDetails?.campaignDetails?.campaignStatus &&
              permissionRole.role === 'Counsellor'
            "
            class="displayFlex"
          >
            <span class="margin-right0p2rem nowrap">Campaign Status:</span>
            <span class="white-text name-truncate" *ngIf="campaignTableDetails.campaignDetails?.campaignStatus">  {{ campaignTableDetails.campaignDetails.campaignStatus }}</span>
          </div>
          <div *ngIf="showCampaignDetails" class="displayFlex">
            <span class="margin-right0p2rem nowrap">Total Records:</span>
            <span class="white-text name-truncate" *ngIf="totalCount || records?.length">{{ totalCount || records.length }}</span>
            <span class="white-text name-truncate" *ngIf="!totalCount && !records?.length">0</span>
          </div>
          <div *ngIf="showCampaignDetails" class="displayFlex">
            <span class="margin-right0p2rem nowrap">Pending:</span>
            <span class="white-text name-truncate" *ngIf="processDetails?.pending"> {{ processDetails.pending }}</span>
            <span class="white-text name-truncate" *ngIf="!processDetails?.pending"> 0</span>
          </div>
          <div *ngIf="showCampaignDetails" class="displayFlex">
            <span class="margin-right0p2rem nowrap">In Progress:</span>
            <span class="white-text name-truncate" *ngIf="processDetails?.inprogress">{{ processDetails.inprogress }}</span>
            <span class="white-text name-truncate" *ngIf="!processDetails?.inprogress">0</span>
          </div>
          <div *ngIf="showCampaignDetails" class="displayFlex">
            <span class="margin-right0p2rem nowrap">Completed:</span>
            <span class="white-text name-truncate" *ngIf="processDetails?.completed">{{ processDetails.completed }}</span>
            <span class="white-text name-truncate" *ngIf="!processDetails?.completed">0</span>
          </div>
          <div *ngIf="showCampaignDetails" class="displayFlex">
            <span class="margin-right0p2rem nowrap">Exited:</span>
            <span class="white-text name-truncate" *ngIf="processDetails?.exited">{{ processDetails.exited}}</span>
            <span class="white-text name-truncate" *ngIf="!processDetails?.exited">0</span>
          </div>
          <div *ngIf="showCampaignDetails" class="displayFlex">
            <span class="margin-right0p2rem nowrap">NC Count:</span>
            <span class="white-text name-truncate" *ngIf="processDetails?.ncstatus">{{ processDetails.ncstatus}}</span>
            <span class="white-text name-truncate" *ngIf="!processDetails?.ncstatus">0</span>
          </div>
          <div *ngIf="showCampaignDetails" class="displayFlex">
            <span class="margin-right0p2rem nowrap">Selected Records:</span>
            <span class="white-text name-truncate">
              {{ selectedRows.length || 0 }}</span
            >
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="ischeckBox" style="width: 1.875rem">
            <p-tableHeaderCheckbox
              [disabled]="checkDisableCdn()"
            ></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let header of headers">
            <ng-container *ngIf="header.isShow || !isShowEditColumns">
              <th
                *ngIf="header.isSortable"
                [pSortableColumn]="header.objectKeyName"
                (click)="
                  applySort(
                    header.sortKey ? header.sortKey : header.objectKeyName
                  )
                "
              >
                {{ header.headerName }}
                <p-sortIcon [field]="header.objectKeyName"></p-sortIcon>
              </th>
              <th *ngIf="!header.isSortable">{{ header.headerName }}</th>
            </ng-container>
          </ng-container>
          <th *ngIf="isTag" style="width: 1.875rem">
            
          </th>
        </tr>
      </ng-template>
      <!-- [ngClass]="{'bg-change': customer['sme_assigned'] === 'status' && permissionRole.role ==='SME'}" -->
      <ng-template pTemplate="body" let-customer><tr
          [class]="tdRowClass"
          [ngClass]="{ row__checked: customer?.isRowChecked, row_disabled:(customer?.remove_status >0 && !customer['latest_status']) , row_disabled_upload_record:(customer?.remove_status >0 && customer['latest_status']) || customer.is_cancelled}"
          (click)="rowSelection.emit(customer)"
          [ngStyle]="{
            'background-color': getBackgroundColor(customer),
            'color': [8, 9, 10].includes(customer['latest_status']) ? '#071426' : '#ffffff',
            'opacity': customer['is_active_today'] === false ? '0.4' : '1'
          }"
        >
          <td
            *ngIf="ischeckBox"
            [ngClass]="{
              'bg-change':
                customer['is_allocated'] === true &&
                permissionRole.role !== 'Admin'
            }"
            (click)="$event.stopPropagation()"
          >
            <p-tableCheckbox
              [value]="customer"
              [disabled]="customer.isDisableCheckbox"
            ></p-tableCheckbox>
          </td>
          <!-- <td *ngIf="!customer.isDisableCheckbox"></td> -->
          <ng-container *ngFor="let header of headers">
            <td
              pEditableColumn
              [ngClass]="{
                'bg-change':
                  customer['is_allocated'] === true &&
                  permissionRole.role !== 'Admin'
              }"
              *ngIf="header.isShow || !isShowEditColumns"
            >
              <span class="p-column-title">{{ header.headerName }}</span>
              <div [ngSwitch]="true">
                <ng-container *ngSwitchCase="header.isCustomStatus">
                  <span
                    [ngStyle]="{
                      color:
                        customer[header.customColorName!] ||
                        header.customColorName
                    }"
                  >
                    {{ customer[header.objectKeyName] }}
                  </span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    !header.isCustomStatus &&
                    (header.objectKeyName === 'status' ||
                      header.objectKeyName === 'Status' ||
                      header.objectKeyName === 'record_status')
                  "
                >
                  <span
                    *ngIf="!campaignStatus"
                    [ngStyle]="{
                      color:
                        (customer[header.objectKeyName] === 'Deactivated' || customer[header.objectKeyName] === false)
                          ? 'var(--color-lara-red)'
                          : 'var(--teal-500)'
                    }"
                  >
                    {{
                      currentModule === 'user' ||
                      currentModule === 'account' ||
                      currentModule === 'faqManagement' ||
                      currentModule === 'dashboardClick'
                        ? (customer[header.objectKeyName] === 'Deactivated' || customer[header.objectKeyName] === false)
                          ? 'Inactive'
                          : 'Active'
                        : customer[header.objectKeyName]
                    }}
                  </span>
                  <span
                    *ngIf="campaignStatus"
                    [ngStyle]="{
                      color:
                        customer[header.objectKeyName] === 'Inactive'
                          ? 'var(--color-lara-red)'
                          : 'var(--teal-500)'
                    }"
                  > 
                    {{
                      currentModule === 'user' ||
                      currentModule === 'account' ||
                      currentModule === 'faqManagement' ||
                      currentModule === 'dashboardClick'
                        ? customer[header.objectKeyName]===true
                          ? 'Active'
                          : 'Inactive'
                        : customer[header.objectKeyName]
                    }}
                  </span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    !header.isCustomStatus &&
                    (header.objectKeyName === 'service_status' ||
                      header.objectKeyName === 'campaign_status')
                  "
                >
                  <span
                    [ngStyle]="{
                      color: customer[header.objectKeyName]
                        ? 'var(--teal-500)'
                        : 'var(--color-lara-red)'
                    }"
                  >
                    {{
                      currentModule === 'contract'
                        ? customer[header.objectKeyName]
                          ? 'Active'
                          : 'Inactive'
                        : customer[header.objectKeyName]
                    }}
                  </span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    header.objectKeyName === 'uploadDoc' && customer.id === 10
                  "
                >
                  <div id="documentSetting">
                    <div class="marginBottom10px logo-cnt">
                      <acengage-fileupload
                        [isMultiUpload]="false"
                        [acceptType]="'.mp4,.mov,.wmv,.avcgd,.flv,.mkx'"
                        [existingFiles]="[]"
                        [maxFileSize]="100000000000"
                        [name]="'upload'"
                        (uploadedData)="fileUploads($event, customer)"
                        [btnLabel]="'Upload Document'"
                        (validationToaster)="showToaster('error', '', $event)"
                      ></acengage-fileupload>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    header.objectKeyName === 'uploadDoc' && customer.id != 10
                  "
                >
                  <div id="documentSetting">
                    <div class="marginBottom10px logo-cnt">
                      <acengage-fileupload
                        [isMultiUpload]="false"
                        [acceptType]="'.doc,.docm,.docx,.pdf,.xlsx,.pptx'"
                        [existingFiles]="[]"
                        [maxFileSize]="10000000"
                        [name]="'upload'"
                        (uploadedData)="fileUploads($event, customer)"
                        [btnLabel]="'Upload Document'"
                        (validationToaster)="showToaster('error', '', $event)"
                      ></acengage-fileupload>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'removeOrientation'"
                >
                  <div class="removeBtn">
                    <acengage-button
                      [buttonText]="'Remove'"
                      [buttonClassesToAdd]="'p-button-danger'"
                      [isBtnDisable]="false"
                      (click)="removeOrientation.emit(customer)"
                      [iconDtls]="{
                        name: 'pi pi-times-circle',
                        position: 'left'
                      }"
                    >
                    </acengage-button>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="header.objectKeyName === 'types'">
                  <a class="isAction" (click)="typeClick.emit(customer)"
                    >View Template</a
                  >
                </ng-container>
                <ng-container *ngSwitchCase="header.objectKeyName === 'tutorial_desc'">
                  <div class="tutorial-video">
                    <video [src]="customer.file + '#t=5'"></video>
                  </div>
                  <div>Filesize: {{customer.size}}</div>
                  <div>Filetype: {{customer.file_type}}</div>
                  <a class="isAction" (click)="tutorialAction(customer, 'download-video')"
                    >Download Tutorial</a>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'removeBtn'"
                >
                <acengage-button
                  [buttonText]="'Remove'"
                  [buttonClassesToAdd]="'p-button-outlined p-button-secondary'"
                  (buttonClicked)="tutorialAction(customer, 'remove-video')">
                </acengage-button>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'viewDoc'"
                >
                  <a class="isAction" (click)="downloadDoc.emit(customer)"
                    >View Template</a
                  >
                </ng-container>

                <ng-container
                  *ngSwitchCase="
                    !header.isCustomStatus &&
                    header.objectKeyName === 'contract_status'
                  "
                >
                  <span
                    [ngStyle]="{
                      color:
                        customer[header.objectKeyName] === 'True'
                          ? 'var(--teal-500)'
                          : 'var(--color-lara-red)'
                    }"
                  >
                    {{
                      currentModule === 'contract'
                        ? customer[header.objectKeyName] === 'True'
                          ? 'Active'
                          : 'Inactive'
                        : customer[header.objectKeyName]
                    }}
                  </span>
                </ng-container>
                
                <ng-container
                  *ngSwitchCase="
                    !header.isCustomStatus &&
                    header.objectKeyName === 'buddy_induction_status'
                  "
                >
                  <span
                    [ngStyle]="{
                      color: 'var(--teal-500)' 
                    }"
                  >
                  {{
                    customer['status'] == 0 ? 'Not Attempted' :
                    customer['status'] == 1 ? 'RNR' :
                    customer['status'] == 2 ? 'Exited' :
                    customer['status'] == 3 ? 'Scheduled' :
                    customer['status'] == 4 ? 'Completed' :
                    'Unknown Status'
                  }}
                  </span>
                </ng-container>

                <ng-container
                  *ngSwitchCase="!header.isCustomStatus && header.objectKeyName === 'old_value'"
                >
                  <span
                    [pTooltip]="customer[header.objectKeyName]"
                    [tooltipDisabled]="
                      customer[header.objectKeyName]?.toString()?.length <= 20
                    "
                    [ngClass]="{ 'name-truncate': !header.showFullContent }"
                    class="copy-text"
                    (click)="copyToClipboard(customer['old_value'])"
                    style="cursor: pointer;" 
                  >
                    {{ customer['old_value'] }}
                  </span>
                </ng-container>

                <ng-container
                  *ngSwitchCase="!header.isCustomStatus && header.objectKeyName === 'account_history_subject'"
                >
                  <span
                    [pTooltip]="customer['subject']"
                    [tooltipDisabled]="
                      customer['subject']?.toString()?.length <= 20
                    "
                    [ngClass]="{ 'name-truncate': !header.showFullContent }"
                    class="copy-text"
                    (click)="copyToClipboard(customer['subject'])"
                    style="cursor: pointer;" 
                  >
                    {{ customer['subject'] }}
                  </span>
                </ng-container>

                <ng-container
                  *ngSwitchCase="!header.isCustomStatus && header.objectKeyName === 'account_history_body'"
                >
                  <span
                    [pTooltip]="formatParagraph(customer['body'])"
                    [tooltipDisabled]="
                      customer['body']?.toString()?.length <= 20
                    "
                    [ngClass]="{ 'name-truncate': !header.showFullContent }"
                    class="copy-text"
                    (click)="copyToClipboard(customer['body'])"
                    style="cursor: pointer;"
                  >
                    {{ formatParagraph(customer['body']) }}
                  </span>
                </ng-container>

                <ng-container
                  *ngSwitchCase="!header.isCustomStatus && header.objectKeyName === 'new_value'"
                >
                  <span
                    [pTooltip]="customer[header.objectKeyName]"
                    [tooltipDisabled]="
                      customer[header.objectKeyName]?.toString()?.length <= 20
                    "
                    [ngClass]="{ 'name-truncate': !header.showFullContent }"
                    class="copy-text"
                    (click)="copyToClipboard(customer['new_value'])"
                    style="cursor: pointer;" 
                  >
                    {{ customer['new_value'] }}
                  </span>
                </ng-container>


                <ng-container
                  *ngSwitchCase="
                    !header.isCustomStatus &&
                    header.objectKeyName === 'user_list_counselor_category'
                  "
                >
                  <span>
                    {{
                      customer['counselor_category'] == 1 ? 'New' :
                      customer['counselor_category'] == 2 ? 'Old' :
                      customer['counselor_category'] == 3 ? 'Both' :
                      ''
                    }}
                  </span>
                </ng-container>

                <ng-container
                  *ngSwitchCase="
                    !header.isCustomStatus &&
                    header.objectKeyName === 'user_list_is_exits_manual_call_allowed'
                  "
                >
                  <span>
                    {{
                      customer['is_exits_manual_call_allowed'] === true ? 'Yes' : 
                        customer['is_exits_manual_call_allowed'] === false ? 'No' : ''
                    }}
                  </span>
                </ng-container>
                
                <ng-container
                  *ngSwitchCase="
                    !header.isCustomStatus &&
                    header.objectKeyName === 'welcome_email_status'
                  "
                >
                  <span
                    [ngStyle]="{
                      color: 'var(--teal-500)' 
                    }"
                  >
                  {{
                    customer['status'] ? 'Yes' : 'No'
                  }}
                  </span>
                </ng-container>
                
                <!-- <ng-container
                  *ngSwitchCase="
                    header.objectKeyName === 'Status (Latest Status)'
                  "
                >
                  <span
                    *ngIf="currentModule === 'campaigns'"
                    [ngStyle]="{
                      color:
                        customer[header.objectKeyName] === 'Non - Contactable'
                          ? 'var(--teal-500)'
                          : 'var(--color-lara-red)'
                    }"
                  >
                    {{
                      currentModule === 'campaigns'
                        ? customer[header.objectKeyName]
                        : ''
                    }}
                  </span>
                </ng-container> -->

                <ng-container *ngSwitchCase="header.objectKeyName === 'online'">
                  <span
                    *ngIf="currentModule === 'dashboard_exit'"
                    [ngStyle]="{
                      color:
                        customer[header.objectKeyName] === 'Yes'
                          ? 'var(--teal-500)'
                          : 'var(--color-lara-red)'
                    }"
                  >
                    {{
                      currentModule === 'dashboard_exit'
                        ? customer[header.objectKeyName]
                        : ''
                    }}
                  </span>
                  <span
                    *ngIf="currentModule === 'dashboard'"
                    [ngStyle]="{
                      color:
                        customer[header.objectKeyName] === 'Yes'
                          ? 'var(--teal-500)'
                          : 'var(--color-lara-red)'
                    }"
                  >
                    {{
                      currentModule === 'dashboard'
                        ? customer[header.objectKeyName]
                        : ''
                    }}
                  </span>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="!DateFormat">
                  <span class="xcxc">{{ customer[header.objectKeyName]}}</span>
                </ng-container> -->
                <ng-container *ngSwitchCase="header.dateFormat && DateFormat"> 
                  <!-- <span *ngIf="toLocalDate(customer[header.objectKeyName],header.dateFormat)!='Invalid date'" && header.objectKeyName=='end_date'>{{customer[header.objectKeyName]? customer[header.objectKeyName]: null}}</span> -->
                  <span *ngIf="toLocalDate(customer[header.objectKeyName],header.dateFormat)!='Invalid date'">
                    <span *ngIf="currentModule==='campaign'">{{customer[header.objectKeyName]}}</span>
                    <span *ngIf="currentModule!='campaign'">{{customer[header.objectKeyName]? toLocalDate(customer[header.objectKeyName],header.dateFormat): null}}</span>
                  </span>
                  <span *ngIf="toLocalDate(customer[header.objectKeyName],header.dateFormat)==='Invalid date'">{{customer[header.objectKeyName]}}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    (header.isDateField === true ||
                    header.objectKeyName === 'Created On' ||
                    header.objectKeyName === 'Modified On' ||
                    header.objectKeyName === 'Last Call Date' ||
                    header.objectKeyName === 'created_at' ||
                    header.objectKeyName === 'modified_at' ||
                    header.objectKeyName === 'updated_at' ||
                    header.objectKeyName === 'created_on' ||
                    header.objectKeyName === 'opened_on' ||
                    header.objectKeyName === 'modified_on' ||
                    header.objectKeyName === 'created_date' ||
                    header.objectKeyName === 'modified_date' ||
                    header.objectKeyName === 'closed_on' ||
                    header.objectKeyName === 'response_received_on' ||
                    header.objectKeyName === 'notes_updated_on' ||
                    header.objectKeyName === 'interview_start_date' ||
                    header.objectKeyName === 'interview_end_date')
                    && !header.dateFormat
                    && DateFormat"
                >
                  <span>{{
                    (customer[header.objectKeyName] 
                      && customer[header.objectKeyName] != '-'
                    ) ? toLocalDate(
                          customer[header.objectKeyName],
                          header.dateFormat
                        )
                      : '-'
                  }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'employement_type'"
                >
                  <span>{{
                    getJobTypeName(customer[header.objectKeyName])
                  }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'modified_by'"
                >
                  <ng-container *ngIf="customer['sub_type'] && customer['sub_type'] == 2; else elseBlock">
                    <img 
                      style="width:5rem;height:auto" 
                      src="../../shared-assets/images/acengageLogo.png" 
                      alt="icon" 
                    />
                  </ng-container>
                  
                  <ng-template #elseBlock>
                    <ng-container *ngIf="customer['is_updated_via_api'] && isTag; else defaultBlock">
                      <p-tag 
                        class="p-tag-table" 
                        style="font-size: 0.8rem;" 
                        severity="info" 
                        [rounded]="true" 
                        value="DocuLens">
                      </p-tag>
                    </ng-container>
                    
                    <ng-template #defaultBlock>
                      {{ customer[header.objectKeyName] }}
                    </ng-template>
                  </ng-template>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'is_mail_triggered' || header.objectKeyName === 'data_request'"
                >
                  <span>{{
                    customer[header.objectKeyName]==true?'Yes':'No'
                  }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'is_tata_dialer'"
                >
                <acengage-button
                [buttonText]="'Call'"
                [buttonClassesToAdd]="'p-button-info'"
                [isBtnDisable]="false"
                (click)="tataDialer.emit(customer);$event.stopPropagation()"
                [iconDtls]="{
                  name: 'pi pi-phone',
                  position: 'left'
                }"
              >
              </acengage-button>
                </ng-container>

                <ng-container *ngSwitchCase="header.objectKeyName === 'lead'">
                  <acengage-select
                    [classesToAdd]="'campaign-dropdown'"
                    [options]="dateType"
                    [isShowFloatLabel]="false"
                    (ngModelChange)="dateTypeSelection($event)"
                    [(ngModel)]="leadTypeModel"
                  ></acengage-select>
                </ng-container>
                <ng-container *ngSwitchCase="header.objectKeyName === 'recording_url'">
                  <div class="audioStyle">
                    <audio controls controlsList="nodownload">
                      <source [src]="customer[header.objectKeyName]" type="audio/mpeg">
                      Your browser does not support the audio element.
                    </audio>
                
                    <acengage-button 
                      *ngIf="customer['isShowAudioDownload']"
                      [buttonClassesToAdd]="'p-button-secondary'"
                      [isBtnDisable]="false"
                      [tooltipText]="'Download Audio'"
                      (click)="audioDownload.emit(customer[header.objectKeyName]); $event.stopPropagation()"
                      [iconDtls]="{ name: 'pi pi-download', position: 'left' }"
                    ></acengage-button>
                
                    <!-- Add pi pi-copy button -->
                    <button 
                      *ngIf="customer['isShowAudioDownload']"
                      class="p-button p-button-secondary copy-btn"
                      (click)="copyToClipboard(customer[header.objectKeyName]); $event.stopPropagation()"
                      title="Copy URL to Clipboard"
                    >
                      <i class="pi pi-copy"></i>
                    </button>
                  </div>
                </ng-container>
                

                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'spoc_name'"
                >
                  <span>{{ customer['spoc_details']['name'] }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'spoc_number'"
                >
                  <span>{{ customer['spoc_details']['mobile'] }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'flag_report_status'"
                >
                  <span [ngStyle]="{
                    color: 
                      customer['status'] === 'Open' 
                        ? 'var(--color-lara-red)' 
                        : (customer['status'] === 'Closed' 
                          ? 'var(--color-lara-green)' 
                          : 'var(--color-lara-yellow)')
                  }" [ngClass]="{ 'name-truncate': !header.showFullContent }" >{{ customer['status'] }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'spoc_email'"
                >
                  <span [ngClass]="{ 'name-truncate': !header.showFullContent }">{{ customer['spoc_details']['email'] }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="header.objectKeyName === 'flag_report_spoc_email'"
                >
                  <span [ngClass]="{ 'name-truncate': !header.showFullContent }">{{ customer['spoc_email'] }}</span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    header.objectKeyName === 'counsellor_assigned' &&
                    currentModule === 'campaign'
                  "
                >
                  <span [pTooltip]="customer[header.objectKeyName]"
                    >{{
                      getCounsellorAssigned(customer[header.objectKeyName])
                    }}
                    <span *ngIf="counsellorAssign.length > 3"
                      >..+{{ counsellorAssign.length - 3 }}</span
                    ></span
                  >
                </ng-container>
                <ng-container *ngSwitchCase="header.objectKeyName === 'file'">
                  <a
                    class="isAction"
                    (click)="dataActionTriggered.emit(customer)"
                    >Download</a
                  >
                </ng-container>
                <ng-container *ngIf="header.objectKeyName === 'buddy_induction_status'">
                  <ng-container *ngIf="isIndividualCellEditable">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <acengage-select 
                          [options]="buddyInductionStatusList" 
                          [isMultiSelect]="false"
                          [placeHolder]="''"
                          (keydown.ArrowRight)="doNothing($event)"
                          (keydown.ArrowLeft)="doNothing($event)"
                          (keydown.ArrowUp)="doNothing($event)"
                          (keydown.ArrowDown)="doNothing($event)"
                          (keydown.enter)="
                            sendValueKey(
                              customer[header.objectKeyName],
                              'status',
                              customer['id'],
                              header.minLength
                            )
                          "
                          (blur)="
                            sendValueKey(
                              customer[header.objectKeyName],
                              'status',
                              customer['id'],
                              header.minLength
                            )
                          "
                          [(ngModel)]="customer[header.objectKeyName]"
                          (ngModelChange)="sendValueKey(
                            customer[header.objectKeyName],
                            'status',
                            customer['id'],
                            header.minLength
                          )"
                          ></acengage-select>
                      </ng-template>
                    </p-cellEditor>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <ng-container *ngIf="!isIndividualCellEditable">
                    <span
                      [pTooltip]="customer[header.objectKeyName]"
                      [tooltipDisabled]="
                        customer[header.objectKeyName]?.toString()?.length <= 20
                      "
                      [ngClass]="{ 'name-truncate': !header.showFullContent }"
                    >
                      {{ customer[header.objectKeyName] }} 
                      <!-- <ng-container *ngIf="header.objectKeyName == 'Id' && customer['is_new']">
                        <span class="badge">New</span>
                      </ng-container> -->
                    </span>
                  </ng-container>                  
                  <ng-container *ngIf="isIndividualCellEditable">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="text"
                          (keydown.ArrowRight)="doNothing($event)"
                          (keydown.ArrowLeft)="doNothing($event)"
                          (keydown.ArrowUp)="doNothing($event)"
                          (keydown.ArrowDown)="doNothing($event)"
                          [disabled]="
                            header.objectKeyName === 'Assigned To' ||
                            header.objectKeyName === 'Status' ||
                            (header.isDisable ? true : false)
                          "
                          (keydown.enter)="
                            sendValueKey(
                              customer[header.objectKeyName],
                              header.objectKeyName,
                              customer['id'],
                              header.minLength
                            )
                          "
                          (blur)="
                            sendValueKey(
                              customer[header.objectKeyName],
                              header.objectKeyName,
                              customer['id'],
                              header.minLength
                            )
                          "
                          [(ngModel)]="customer[header.objectKeyName]"
                        /> 
                      </ng-template>

                      <ng-template pTemplate="output">
                        <span
                          [pTooltip]="customer[header.objectKeyName]"
                          [tooltipDisabled]="
                            customer[header.objectKeyName]?.toString()
                              ?.length <= 20
                          "
                          [ngClass]="{
                            'name-truncate': !header.showFullContent
                          }"
                          >{{ customer[header.objectKeyName] }}</span
                        >
                      </ng-template>
                    </p-cellEditor>
                  </ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="
                      header.objectKeyName === 'buddy_action'
                    "
                  >
                    <span>
                      <acengage-button
                        [buttonClassesToAdd]="'p-button-danger'"
                        [iconDtls]="{ name: 'pi pi-times-circle', position: 'right' }"
                        (buttonClicked)="deleteRecord(customer['id'])"
                      ></acengage-button>
                    </span>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    header.objectKeyName === 'welcome_email_action'
                  "
                >
                  <span>
                    <acengage-button
                      [isBtnDisable]="!customer['status'] && customer['is_active']"
                      [buttonText]="'Trigger Email'"
                      [buttonClassesToAdd]="'p-button-info'"
                      [iconDtls]="{ position: 'left' }"
                      (buttonClicked)="triggerEmployeeEmail(customer['type'])"
                    > 
              </acengage-button>
                  </span>
                </ng-container>
              </div>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr class="empty__message">
          <td colspan="11">{{showSearchMsg?'Please search or apply a filter to display the records.':'No records found.'}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="total_count">
        <div class="total__container">
          <p>In total there are {{ totalCount }} products.</p>
        </div>
      </ng-template>
    </p-table>
    <div class="table--footer">
      {{
        tableFooterText
          ? tableFooterText
          : totalCount > 1
          ? 'In total there are ' +
            ((totalCount || (records && records.length) || 0)) +
            ' records.'
          : 'In total there is ' +
            ((totalCount || (records && records.length) || 0)) +
            ' record.'
      }}
    </div>
  </div>
  <ng-container *ngIf="isShowEditColumns">
    <acengage-edit-table-columns
      [headerList]="headers"
      (saveChanges)="saveColumnChanges($event)"
      [disableBtn]="!records || records.length === 0 || totalCount === 0"
    >
    </acengage-edit-table-columns>
  </ng-container>
</div>

import { AcengageTreeForm } from '@acengage/app/app/acengage-tree/models/add-acengage-tree.interface';
import { SelectOption } from '@acengage/app/app/models/select.model';
import { AcengageTreeService } from '@acengage/app/app/services/acengage-tree.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'acengage-tree-reason-fields',
  templateUrl: './tree-reason-fields.component.html',
  styleUrls: ['./tree-reason-fields.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TreeReasonFieldsComponent implements OnInit {
  constructor(private treeService: AcengageTreeService) { }
  addIcon = {
    name: 'pi pi-plus',
    position: 'left',
  };
  removeIcon = {
    name: 'pi pi-minus',
    position: 'left',
  };
  @Input() treeForm = {} as AcengageTreeForm;
  @Input() leafIndex: number;
  @Input() isShowServices = true;
  @Input() isPrimaryReason = false;
  @Input() processType: string | null = '';
  @Input() parentLeafNode: any;
  @Input() mappingFields: SelectOption[] = [];
  @Input() serviceId:any;
  @Output() removeNodeClicked = new EventEmitter<number>();
  randKey: number;
  followUp=false;
  ngOnInit(): void { 
    //console.log('processType',this.processType)
    
  }

  addLeafNodes() { 
    this.parentLeafNode.leaf_nodes = [...this.parentLeafNode.leaf_nodes, this.treeService.newNodeOptionFG((this.parentLeafNode?.drill_down || 1) + 1)];
  }

  removeNode(removeableIndex: number) {
    this.parentLeafNode.leaf_nodes?.splice(removeableIndex, 1);
    this.parentLeafNode.leaf_nodes = [...this.parentLeafNode.leaf_nodes]
  }

  getRandKey() {
    if (!this.randKey) {
      this.randKey = Math.random();
    }

    return this.randKey;
  }

}

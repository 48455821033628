import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable,map,Subscription } from 'rxjs';
import { LoginState } from '../state/auth.reducer';
import { getLoginStates } from '../state/auth.selectors';
import { CommonService } from '@acengage/app/app/services/common.service';

@Component({
  selector: 'acengage-scan-qr-code',
  templateUrl: './scan-qr-code.component.html',
  styleUrls: ['./scan-qr-code.component.scss'],
})
export class ScanQrCodeComponent implements OnInit {
  qrText = '';
  toasterInfoObj = {
    severity: 'success',
    summary: '',
    detail: '',
  };
  displayToast: boolean = false;
  localStoreValues: any;
  qrCodeText: any;
  qrCodeValue$: Observable<any>;
  storeSubscribed$:Subscription;
  constructor(private router: Router,
    private store1: Store<LoginState>,
    private commonService: CommonService,
    ) {}

  ngOnInit(): void {
   
    // this.qrCodeValue$ = this.store1.select(getLoginStates).pipe(map((state)=> state.qr_text));
    this.commonService.getQRCode().subscribe((qrResponse: any) => {
      this.qrCodeText = qrResponse.qr_text
      this.qrCodeValue$ = this.store1.select(getLoginStates).pipe(
        map((state) => state.qr_text !== undefined ? state.qr_text : this.qrCodeText)
      );
    })
    
    this.storeSubscribed$=this.store1.select(getLoginStates).subscribe((state) => {
      this.localStoreValues = { appStates: state };
      if(
        this.localStoreValues.appStates.id=='' 
        || !this.localStoreValues.appStates.id 
        || this.localStoreValues.appStates.id==undefined 
        || !this.localStoreValues.appStates.hasOwnProperty('qr_text') 
        || (this.localStoreValues.appStates.hasOwnProperty('qr_text') 
          && this.localStoreValues.appStates.qr_text==undefined)
        ){
          if(this.localStoreValues.appStates.roles[0].id !== 11 && this.localStoreValues.appStates.is_client_2fa_verified && !this.localStoreValues.appStates.is_client_2fa_verified.is_2fa) {
            this.router.navigateByUrl('/auth/login')
          }
      }
    })
  }
  setup2FA() {
    this.router.navigateByUrl('/auth/two-factor-auth')
  }
  checkQrText(){
    return !this.qrCodeValue$
  }
  ngOnDestroy() {
    this.storeSubscribed$.unsubscribe()
  }
}

import { CommonButtonInput } from '@acengage/ui/models/button.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acengage-table-buttons',
  templateUrl: './table-buttons.component.html',
  styleUrls: ['./table-buttons.component.scss']
})
export class TableButtonsComponent implements OnInit {

  constructor() { }

  @Input() buttonDetails: CommonButtonInput[] = [];
  @Input() disableBtn = false;
  @Input() selectedRows: Array<any> = [];
  @Output() buttonClicked = new EventEmitter<string>();

  ngOnInit(): void {
  }

}

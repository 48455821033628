import { CommonButtonInput } from '@acengage/ui/models/button.model';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'acengage-user-input-popup',
  templateUrl: './user-input-popup.component.html',
  styleUrls: ['./user-input-popup.component.scss'],
})
export class UserInputPopupComponent implements OnInit {

  @Input() confirmBtn: CommonButtonInput = {
    buttonText: 'Confirm',
    buttonClassesToAdd: 'primary-btn',
    iconDetails: { },
  };
  @Input() cancelBtn: CommonButtonInput = {
    buttonText: 'Cancel',
    buttonClassesToAdd: 'secondary-btn',
    iconDetails: { },
  };
  @Input() actionMessage: string;
  @Input() item: any;
  @Output() closeClicked = new EventEmitter<void>();
  @Output() confirmClicked = new EventEmitter<any>();
  popupForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.popupForm = this.fb.group({
      item: ['', Validators.required],
    });
  }

  confirmAction() {
    this.confirmClicked.emit(
      {
        formData: this.popupForm.value,
        inputItem: this.item
      }
    )
  }
}

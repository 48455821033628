<div class="header">
    <acengage-logo></acengage-logo>
</div>
<div class="content-wrap" *ngIf="!candidateDtls.is_submitted">
    <h2>Welcome {{candidateDtls.email || (candidateDtls.candidate_name | titlecase)}}</h2>
    <h3>We regret to inform you that we have been unable to establish contact with you. <br/>
        Kindly furnish us with a suitable date and time for communication at your earliest convenience.</h3>
    <div class="email-content">
        <div class="each-data-wrap">
            <div class="each-data">
                <h4>Candidate Name</h4>
                <h5>{{candidateDtls.candidate_name}}</h5>
            </div>
            <div class="each-data">
                <h4>Candidate ID</h4>
                <h5>{{candidateDtls.candidate_id}}</h5>
            </div>
            <div class="each-data">
                <h4>Date of Joining</h4>
                <h5>{{candidateDtls.date_of_join | date: 'dd/MM/YYYY'}}</h5>
            </div>

            <!-- <div class="each-data">
                <h4>{{getRecruiterKeyName() | titlecase}} Name</h4>
                <h5>{{candidateDtls.stakeholder_name || '-'}}</h5>
            </div>
            <div class="each-data">
                <h4>Location</h4>
                <h5>{{candidateDtls.location || '-'}}</h5>
            </div>
            <div class="each-data">
                <h4>BU/Tower/Dept</h4>
                <h5>{{candidateDtls.department || '-'}}</h5>
            </div> -->
        </div>
        <div class="form-wrap">
            <h4>Choose the preferred option</h4>
            <div class="data-wrap">
                <div class="each-data">
                    <acengage-checkbox-input [options]="option" [showVeritically]="true"
                    [behaveLikeRadio]="true" [isNeedOutput]="true" (checkboxClicked)="slotSelected($event)">
                    </acengage-checkbox-input>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isCustomClicked">
            <form [formGroup]="slotSelectionForm">
                <div class="form-wrap">
                    <h4>Select Preferred Slot </h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_date" [minDate]="todayDate"
                                [placeholder]="'Select Date *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls['slot_date']"
                                validatorName="required">
                                Slot date is required
                            </acengage-form-error>
                        </div>
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_time" [icon]="'pi pi-clock'" [timeOnly]="true"
                                [showSeconds]="false" [placeholder]="'Select Time (IST) *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls['slot_time']"
                                validatorName="required">
                                Slot time is required
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                <div class="button-section">
                    <div class="button">
                        <acengage-button buttonText="Submit" [isBtnDisable]="slotSelectionForm.invalid"
                            (buttonClicked)="submitSlotSelection()">
                        </acengage-button>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="isRejectClicked">
            <form [formGroup]="slotSelectionForm">
                <div class="form-wrap">
                    <h4>Reason for terminating the process</h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-text-input formControlName="note"
                                [placeholder]="'Reason'">
                            </acengage-text-input>
                            <acengage-form-error [control]="slotSelectionForm.controls['note']" validatorName="required">
                                Reason is required
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                <div class="button-section">
                    <div class="button">
                        <acengage-button buttonText="Submit" [isBtnDisable]="slotSelectionForm.invalid"
                            (buttonClicked)="submitSlotSelection();">
                        </acengage-button>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</div>
<div class="popup" *ngIf="candidateDtls.is_submitted">
    <acengage-slot-selection-success></acengage-slot-selection-success>
</div>
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ButtonComponent } from './button/button.component';
import { SelectComponent } from './select/select.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { PasswordModule } from 'primeng/password';
import { LogoComponent } from './logo/logo.component';
import { BorderButtonComponent } from './border-button/border-button.component';
import { GoogleIconButtonComponent } from './google-icon-button/google-icon-button.component';
import { TableComponent } from './table/table.component';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input/search-input.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsIconComponent } from './settings-icon/settings-icon.component';
import { AccountsIconComponent } from './accounts-icon/accounts-icon.component';
import { NotificationIconComponent } from './notification-icon/notification-icon.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { HttpClientModule } from '@angular/common/http';
import { HeaderWithRightButtonComponent } from './header-with-right-button/header-with-right-button.component';
import { StepsComponent } from './steps/steps.component';
import { StepsModule } from 'primeng/steps';
import { TextAreaComponent } from './text-area/text-area.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { RadioBtnComponent } from './radio-btn/radio-btn.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TabsComponent } from './tabs/tabs.component';
import { TabViewModule } from 'primeng/tabview';
import { ValueAccessorService } from '../shared-service/valueAccessor.service';
import { TextInputMaskComponent } from './text-input-mask/text-input-mask.component';
import { InputMaskModule } from 'primeng/inputmask';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ToasterComponent } from './toaster/toaster.component';
import { LoaderComponent } from './loader/loader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogComponent } from './dialog/dialog.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { MenuModule } from 'primeng/menu';
import { RatingComponent } from './rating/rating.component';
import { RatingModule } from 'primeng/rating';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { EditTableColumnsComponent } from './table/edit-table-columns/edit-table-columns.component';
import { DragDropModule } from 'primeng/dragdrop';
import { FilterTableComponent } from './table/filter-table/filter-table.component';
import { AccordionModule } from 'primeng/accordion';
import { AccordionComponent } from './accordion/accordion.component';
import { TemplateMarkerDirective } from './directives/templateMarker/template-marker.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DisableInteractionDirective } from './directives/disable-interactions.directive';
import { KeyFilterModule } from 'primeng/keyfilter';
import { EditableTableComponent } from './editable-table/editable-table.component';
import { ProductService } from './editable-table/productservice';
import { SelectButtonComponent } from './select-button/select-button.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableButtonsComponent } from './table/table-buttons/table-buttons.component';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { MultiSelectLazyComponent } from './multi-select-lazy/multi-select-lazy.component';
import { VerticalTabsComponent } from './vertical-tabs/vertical-tabs.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { PlyrModule } from 'ngx-plyr';
import { EditorModule } from 'primeng/editor';

@NgModule({
  imports: [
    InputTextModule,
    ButtonModule,
    FormsModule,
    PasswordModule,
    TableModule,
    TagModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ToastModule,
    ProgressBarModule,
    DropdownModule,
    CommonModule,
    BreadcrumbModule,
    HttpClientModule,
    FileUploadModule,
    RadioButtonModule,
    ReactiveFormsModule,
    CheckboxModule,
    StepsModule,
    InputTextareaModule,
    FileUploadModule,
    TabViewModule,
    ProgressSpinnerModule,
    InputMaskModule,
    ProgressSpinnerModule,
    InputSwitchModule,
    MenuModule,
    RatingModule,
    DragDropModule,
    AccordionModule,
    KeyFilterModule,
    SelectButtonModule,
    HttpClientModule,
    VirtualScrollerModule,
    EditorModule,
    NgxDocViewerModule,
    PlyrModule,
  ],
  declarations: [
    ButtonComponent,
    SelectComponent,
    PasswordInputComponent,
    TextInputComponent,
    LogoComponent,
    BorderButtonComponent,
    GoogleIconButtonComponent,
    TableComponent,
    SearchInputComponent,
    SettingsComponent,
    SettingsIconComponent,
    AccountsIconComponent,
    NotificationIconComponent,
    BreadcrumbComponent,
    HeaderWithRightButtonComponent,
    StepsComponent,
    TextAreaComponent,
    FileuploadComponent,
    RadioBtnComponent,
    CheckboxInputComponent,
    TabsComponent,
    ToasterComponent,
    LoaderComponent,
    DialogComponent,
    TextInputMaskComponent,
    DatePickerComponent,
    ToasterComponent,
    LoaderComponent,
    DialogComponent,
    ToggleSwitchComponent,
    TableActionsComponent,
    RatingComponent,
    RangeSliderComponent,
    EditTableColumnsComponent,
    FilterTableComponent,
    AccordionComponent,
    TemplateMarkerDirective,
    ClickOutsideDirective,
    DisableInteractionDirective,
    EditableTableComponent,
    SelectButtonComponent,
    TableButtonsComponent,
    MultiSelectLazyComponent,
    VerticalTabsComponent,
    DocumentViewerComponent,
    VideoPlayerComponent,
  ],
  exports: [
    ButtonComponent,
    SelectComponent,
    PasswordInputComponent,
    TextInputComponent,
    LogoComponent,
    BorderButtonComponent,
    GoogleIconButtonComponent,
    TableComponent,
    SearchInputComponent,
    SettingsComponent,
    SettingsIconComponent,
    AccountsIconComponent,
    NotificationIconComponent,
    BreadcrumbComponent,
    HeaderWithRightButtonComponent,
    StepsComponent,
    TextAreaComponent,
    FileuploadComponent,
    RadioBtnComponent,
    CheckboxInputComponent,
    TabsComponent,
    ToasterComponent,
    LoaderComponent,
    DialogComponent,
    TextInputMaskComponent,
    DatePickerComponent,
    ToasterComponent,
    LoaderComponent,
    DialogComponent,
    ToggleSwitchComponent,
    RatingComponent,
    RangeSliderComponent,
    AccordionModule,
    AccordionComponent,
    ClickOutsideDirective,
    DisableInteractionDirective,
    TemplateMarkerDirective,
    EditableTableComponent,
    FilterTableComponent,
    SelectButtonComponent,
    MultiSelectLazyComponent,
    VerticalTabsComponent,
    DocumentViewerComponent,
    VideoPlayerComponent,
    EditorModule
  ],
  providers: [MessageService, ValueAccessorService, ProductService],
})
export class UiModule {}

<div class="card">
    <p-table [value]="products2" dataKey="id" editMode="row">
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="ischeckBox" style="width: 1.875rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <ng-container *ngFor="let header of headers">
                    <ng-container *ngIf="header.isShow || !isShowEditColumns">
                        <th *ngIf="header.isSortable" [pSortableColumn]="header.objectKeyName"
                            (click)="applySort(header.sortKey ? header.sortKey : header.objectKeyName);">
                            {{header.headerName}}
                            <p-sortIcon [field]="header.objectKeyName"></p-sortIcon>
                        </th>
                        <th *ngIf="!header.isSortable">{{header.headerName}} </th>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="product">
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.code">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{product.code}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.name" required>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{product.name}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="product.price">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{product.price | currency: 'USD'}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="text-align:center">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
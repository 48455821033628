import { Component, OnInit,Input } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { ProductService } from './productservice';
import { Product } from './product';



@Component({
  selector: 'acengage-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
})
export class EditableTableComponent implements OnInit {
  products2: any[];
  isShowEditColumns=false;
  selectedSortCol: string;
  ischeckBox = true;
  @Input() headers=[
    {
      headerName: 'Id',
      objectKeyName: 'id',
      isSortable: false,
      isShow: true,
      sortKey:false,
    },
    {
    headerName: 'Name',
    objectKeyName: 'name',
    isSortable: false,
    isShow: true,
    sortKey:false,
  },
  {
    headerName: 'Code',
    objectKeyName: 'code',
    isSortable: false,
    isShow: true,
    sortKey:false,
  },
];

  clonedProducts: { [s: string]: any; } = {};

  constructor(private productService: ProductService, private messageService: MessageService) { }

  ngOnInit() {
    this.products2 =[{
      id:'1',
      code:"sdfsd",
      name:"Namee",
      description:"deoasdjals",
      price:234,
      quantity:2,
      inventoryStatus:"Noeras",
      category:"Categodyyy",
      image:"asdasd",
      rating:5
    },
    {
      id:'2',
      code:"sdfsd",
      name:"Namee",
      description:"deoasdjals",
      price:234,
      quantity:2,
      inventoryStatus:"Noeras",
      category:"Categodyyy",
      image:"asdasd",
      rating:5
    },
    {
      id:'3',
      code:"sdfsd",
      name:"Namee",
      description:"deoasdjals",
      price:234,
      quantity:2,
      inventoryStatus:"Noeras",
      category:"Categodyyy",
      image:"asdasd",
      rating:5
    },{
      id:'4',
      code:"sdfsd",
      name:"Namee",
      description:"deoasdjals",
      price:234,
      quantity:2,
      inventoryStatus:"Noeras",
      category:"Categodyyy",
      image:"asdasd",
      rating:5
    },
    {
      id:'5',
      code:"sdfsd",
      name:"Namee",
      description:"deoasdjals",
      price:234,
      quantity:2,
      inventoryStatus:"Noeras",
      category:"Categodyyy",
      image:"asdasd",
      rating:5
    }
  
  ]

  }

  onRowEditInit(product: any) {
      this.clonedProducts[product.id] = {...product};
  }

  onRowEditSave(product: any) {
      if (product.price > 0) {
          delete this.clonedProducts[product.id];
          this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
      }  
      else {
          this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
      }
  }

  onRowEditCancel(product: any, index: number) {
      this.products2[index] = this.clonedProducts[product.id];
      delete this.products2[product.id];
  }

  applySort(colName: any) {
    if (colName == this.selectedSortCol) {
      this.selectedSortCol = '-' + colName;
    } else {
      this.selectedSortCol = colName;
    }
    // this.sortTriggered.emit(this.selectedSortCol);
  }
}

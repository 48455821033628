<ng-container *ngIf="isShowServices">
    <acengage-acengage-tree-service-selection [treeForm]="treeForm" [processType]="processType"
        (addClicked)="addClicked.emit();" (resetClicked)="resetClicked.emit();"
        (sendMappingFields)="mappingFields = $event;">
    </acengage-acengage-tree-service-selection>
</ng-container>
<ng-container *ngIf="treeForm.options.length">
    <div class="add__edit__tree--container" [ngClass]="{'remove__margin': !isShowServices}">
        <div class="add__edit__tree--header">Reasons</div>
        <div class="add__edit__tree--each--fields" *ngFor="let eachOption of treeForm.options; let i = index;">
            <acengage-tree-reason-fields [serviceId]="serviceId" [treeForm]="treeForm" [mappingFields]="mappingFields"
                [parentLeafNode]="eachOption" [isPrimaryReason]="true" [leafIndex]="i" [processType]="processType"
                [isShowServices]="isShowServices" (removeNodeClicked)="removeOption($event);">
            </acengage-tree-reason-fields>
        </div>
        <div class="add__edit__tree--btn--container" *ngIf="processType !== 'view' && !nest">
            <acengage-button buttonText="Primary reason" [iconDtls]="addIcon"
                (buttonClicked)="addClicked.emit('new_primary_reason');">
            </acengage-button>
        </div>

       

    </div>
</ng-container>
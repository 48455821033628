<ng-container *ngIf="textToShow.length <= charLimit else showReadMore">
    <span>
        {{textToShow}}
    </span>
</ng-container>
<ng-template #showReadMore>
    <div *ngIf="showAllText">
        {{textToShow}}
        <a class="read__link" (click)="showAllText = false">Read less</a>
    </div>
    <div *ngIf="!showAllText">
        {{textToShow.slice(0, charLimit) ||
        ''}}{{textToShow.length > charLimit ? '...' : ''}}
        <a class="read__link" (click)="showAllText = true">Read more</a>
    </div>
</ng-template>
<div class="bottom-bar" [ngClass]="{'back': (!isPrev || activeStep === 1) && !isBack}">
  <div class="bottom-left" *ngIf="isBack">
    <acengage-button [buttonText]="'Back'" buttonClassesToAdd="secondary-btn"
      [iconDtls]="{name: 'pi pi-arrow-left', position: 'left'}" (buttonClicked)="navigateBtnClicked.emit('back-btn');">
    </acengage-button>
  </div>
  <div class="bottom-left" *ngIf="isPrev && activeStep > 1">
    <acengage-button [buttonText]="prevBtnLabel" buttonClassesToAdd="secondary-btn"
      [iconDtls]="{name: 'pi pi-arrow-left', position: 'left'}" (buttonClicked)="navigateBtnClicked.emit('back');">
    </acengage-button>
  </div>
  <div class="bottom-right">
    <div class="bottom-skip" *ngIf="isSkip && activeStep > 1">
      <acengage-button buttonClassesToAdd="p-button-outlined p-button-secondary" *ngIf="activeStep !== stepItems"
        [buttonText]="skipBtnLabel" (buttonClicked)="navigateBtnClicked.emit('skip');"
        buttonText="Skip"></acengage-button>
      <acengage-button buttonClassesToAdd="p-button-outlined p-button-secondary" *ngIf="activeStep === stepItems"
        (buttonClicked)="navigateBtnClicked.emit('skipandsave');" buttonText="Skip and Save Account "></acengage-button>
    </div>
    <div class="bottom-next">
      <acengage-button *ngIf="activeStep !== stepItems" buttonClassesToAdd="secondary-btn" [buttonText]="nextBtnLabel"
        [isBtnDisable]="nextBtnDisable" [iconDtls]="{name: 'pi pi-arrow-right', position: 'right'}"
        (buttonClicked)="navigateBtnClicked.emit('next');">
      </acengage-button>
      <acengage-button *ngIf="activeStep === stepItems && !submitLabel" [buttonText]="submitBtnLabel" [isBtnDisable]="nextBtnDisable"
        [iconDtls]="{name: 'pi pi-save', position: 'right'}" (buttonClicked)="navigateBtnClicked.emit('submit');">
      </acengage-button>
      <acengage-button *ngIf="activeStep === stepItems && submitLabel" [buttonText]="submitLabel" [isBtnDisable]="nextBtnDisable"
      [iconDtls]="{name: 'pi pi-save', position: 'right'}" (buttonClicked)="navigateBtnClicked.emit('submit');">
    </acengage-button>
    </div>
  </div>
</div>

<span [ngClass]="{'p-float-label' : isShowFloatLabel}" [class]="classesToAdd">
    <ng-container *ngIf="!rightIcon">
        <input *ngIf="!_allowedKeys" [id]="id || placeholder" [type]="inputType" class="form-control-input" min="0"
            [ngClass]="classesToAdd" [maxlength]="maxLength" pInputText [name]="name"
            [placeholder]="isShowFloatLabel ? '' : placeholder" [ngModel]="value" (ngModelChange)="updateValue($event)"
            (blur)="onTouch();" [disabled]="disabled"
            (keydown)="handleEmptySpace($event); preventHTMLTagSymbol($event);" (keypress)="checkAlphanumeric($event);validateLength($event);" (paste)="restrictpastecharacters($event)">
        <input *ngIf="_allowedKeys" [id]="id || placeholder" [type]="inputType" class="form-control-input" min="0"
            [ngClass]="classesToAdd" [pKeyFilter]="_allowedKeys" [pValidateOnly]="validateOnly" [maxlength]="maxLength"
            pInputText [name]="name" [placeholder]="isShowFloatLabel ? '' : placeholder" [ngModel]="value"
            (ngModelChange)="updateValue($event)" (blur)="onTouch();" [disabled]="disabled"
            (keydown)="handleEmptySpace($event); preventHTMLTagSymbol($event);" (keypress)="checkAlphanumeric($event);validateLength($event);" (paste)="restrictpastecharacters($event)">
    </ng-container>

    <ng-container *ngIf="rightIcon">
        <span [ngClass]="{'p-inputgroup':isAddon === true,'p-input-icon-right' : isAddon === false }">
            <ng-content select="[icons]"></ng-content>
            <input *ngIf="!_allowedKeys" [id]="id || placeholder" [type]="inputType" class="form-control-input"
                [ngClass]="classesToAdd" [maxlength]="maxLength" pInputText [name]="name"
                [placeholder]="isShowFloatLabel ? '' : placeholder" [ngModel]="value"
                (ngModelChange)="updateValue($event)" (blur)="onTouch();" [disabled]="disabled"
                (keydown)="handleEmptySpace($event); preventHTMLTagSymbol($event);"
                (keypress)="checkAlphanumeric($event);validateLength($event);" (paste)="restrictpastecharacters($event)">
            <input *ngIf="_allowedKeys" [id]="id || placeholder" [type]="inputType" class="form-control-input" min="0"
                [ngClass]="classesToAdd" [pKeyFilter]="_allowedKeys" [pValidateOnly]="validateOnly"
                [maxlength]="maxLength" pInputText [name]="name" [placeholder]="isShowFloatLabel ? '' : placeholder"
                [ngModel]="value" (ngModelChange)="updateValue($event)" (blur)="onTouch();" [disabled]="disabled"
                (keydown)="handleEmptySpace($event); preventHTMLTagSymbol($event);"
                (keypress)="checkAlphanumeric($event);validateLength($event)" (paste)="restrictpastecharacters($event)">
            <ng-content select="[labels]"></ng-content>
        </span>
    </ng-container>
    <label *ngIf="isShowFloatLabel" [for]="id || placeholder">{{placeholder}}</label>
</span>
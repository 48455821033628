<div class="popup-overlay"></div>
<div class="message-popup">
    <div class="message-content">
        <div class="message-text">{{ actionMessage }}</div>
        <form [formGroup]="popupForm">
            <div class="input-single-fields">
                <div class="marginBottom10px">
                    <acengage-text-input formControlName="item" [inputType]="'text'"
                        [placeholder]="'Credit Number *'">
                    </acengage-text-input>
                    <acengage-form-error [control]="popupForm.controls['item']" validatorName="required">
                        Credit Number is required
                    </acengage-form-error>
                </div>
            </div>
        </form>
        <div class="message-button">
            <acengage-button [buttonText]="cancelBtn.buttonText" [iconDtls]="cancelBtn.iconDetails"
                [buttonClassesToAdd]="cancelBtn.buttonClassesToAdd"
                (buttonClicked)="closeClicked.emit();"></acengage-button>
            <acengage-button [buttonText]="confirmBtn.buttonText" [iconDtls]="confirmBtn.iconDetails"
                [isBtnDisable]="popupForm.invalid"
                [buttonClassesToAdd]="confirmBtn.buttonClassesToAdd"
                (buttonClicked)="confirmAction()"></acengage-button>
        </div>
    </div>
</div>
  <form>
  <h2 class="textCenter">Create a new password</h2>
  <div class="width100">
    <div class="marginBottom10px">
      <label>New password</label>
    </div>
    <div class="marginBottom10px">
      <acengage-password-input
      [maxLength]="'50'"
        [(ngModel)]="newPassword"
        name="newPass"
        [placeholder]="'New Password'"
        [showSuggessions]="true"
        (keydown.space)="$event.preventDefault()"
      >
    </acengage-password-input>
    </div>
  </div>
  <div class="width100">
    <div class="marginBottom10px">
      <label>Confirm password</label>
    </div>
    <div class="marginBottom20px">
      <acengage-password-input
        [(ngModel)]="confirmNewPassword"
        [maxLength]="'50'"
        name="confirmPass"
        [placeholder]="'Re-enter Password'"
        (keydown.space)="$event.preventDefault()"
      ></acengage-password-input>
    </div>
    <div class="marginBottom10px marginTop2rem">
      <acengage-button
      [isBtnDisable]="isValidPassword()"
        (buttonClicked)="setNewpassword()"
        buttonText="Create Password"
        buttonType="submit"
      ></acengage-button>
    </div>
  </div>
</form>

import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SliderValues } from '@acengage/app/app/models/data';
import { SelectOption } from '@acengage/app/app/models/select.model';
import { QuestionsForm, QuestionCheckboxTypeForm,NestedCheckBoxForm } from '@acengage/app/app/question-bank/models/add-question-bank.model';

@Component({
  selector: 'acengage-slider-question-type',
  templateUrl: './slider-question-type.component.html',
  styleUrls: ['./slider-question-type.component.scss', '../../add-edit-questions.component.scss'],
})
export class SliderQuestionTypeComponent implements OnInit {
  @Input() questionCheckboxTypeForm:FormGroup<QuestionCheckboxTypeForm>
  @Input() nestedCheckBoxForm:FormGroup<NestedCheckBoxForm>
  @Input() questionForm: FormGroup<QuestionsForm>
  fromSlider: SelectOption[] = [...SliderValues.map((eachVal: SelectOption) => ({ ...eachVal }))];
  toSlider: SelectOption[] = [...SliderValues.map((eachVal: SelectOption) => ({ ...eachVal }))];
  nestFromSlider: SelectOption[] = [...SliderValues.map((eachVal: SelectOption) => ({ ...eachVal }))];
  nestToSlider: SelectOption[] = [...SliderValues.map((eachVal: SelectOption) => ({ ...eachVal }))];
  @Output() removeFollowup = new EventEmitter<number>();
  @Output() addFollowup = new EventEmitter<number>();
  @Input() isEdit:boolean=false;
  sliderNest=false;
  from:number=0;
  to:number=10;
  fromNest:number=0;
  toNest:number=10;
  ngOnInit() {
    this.sliderSelected(this.questionForm?.value?.from || 0, 'from');
    this.sliderSelected(this.questionForm?.value?.to || 10, 'to');
    this.sliderFollowUpSelected(this.questionForm?.value?.follow_up_from || 0, 'follow_up_from');
    this.sliderFollowUpSelected(this.questionForm?.value?.follow_up_to || 10, 'follow_up_to');

    if(this.isEdit){ 
      if(this.questionForm.value['followup_question']){
        this.addFollowup.emit();
        this.sliderNest=true;
      }else{
        this.sliderNest=false;
      }
    }
  } 
  sliderSelected(selectedValue: number, type: string) {
    if (type === 'from') {
      this.from = selectedValue;
      this.toSlider = [...this.toSlider.map((eachValue: SelectOption) => {
        if (Number(eachValue.value) <= this.from) {
          eachValue.disabled = true;
        } else {
          eachValue.disabled = false;
        }
        return eachValue;
      })];
    } else {
      this.to = selectedValue;
      this.fromSlider = [...this.fromSlider.map((eachValue: SelectOption) => {
        if (Number(eachValue.value) >= this.to) {
          eachValue.disabled = true;
        } else {
          eachValue.disabled = false;
        }
        return eachValue;
      })];
    }
     this.nestFromSlider = [...this.nestFromSlider.map((eachVal: SelectOption) => {
        if (Number(eachVal.value) < this.from) {
          eachVal.disabled = true;
        } else {
          if(Number(eachVal.value) > this.to){
            eachVal.disabled = true;
          }else{
            eachVal.disabled = false;
          }
        }
        return eachVal;
      })];
      this.nestToSlider = [...this.nestToSlider.map((eachVal: SelectOption) => {
        if (Number(eachVal.value) < this.from) {
          eachVal.disabled = true;
        } else {
          if(Number(eachVal.value) > this.to){
            eachVal.disabled = true;
          }else{
            eachVal.disabled = false;
          }
          
        }
        return eachVal;
      })];
  }
  sliderFollowUpSelected(selectedValue: number, type: string) {
    if (type === 'follow_up_from') {
      this.fromNest = selectedValue;
      this.nestToSlider = [...this.nestToSlider.map((eachVal: SelectOption) => {
        if (Number(eachVal.value) < this.from) {
          eachVal.disabled = true;
        } else {
          if(Number(eachVal.value) > this.to){
            eachVal.disabled = true;
          }else{
            if (Number(eachVal.value) <= this.fromNest) {
              eachVal.disabled = true;
            } else {
              eachVal.disabled = false;
            }
          }
          
        }
        return eachVal;
      })];


    }else if(type === 'follow_up_to'){
      this.toNest = selectedValue; 
    }
  } 
  removeNest(){
    this.sliderNest=false; 
    this.removeFollowup.emit()
  }
}

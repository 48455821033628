<div class="header">
    <img class="logoCss" src="shared-assets/images/acengageLogo.png"
/>
</div>
<div class="content-wrap" *ngIf="!flagReportDetails.is_submitted">
    <h2 class="exit__report--title">
        {{ flagReportDetails?.report_type | uppercase }} FLAG REPORT
    </h2>
    <div class="email-content">
        <div class="exit__report--content">
            <p><strong>Name of the organization:</strong> {{ flagReportDetails.client ?? '-' }}</p>
            <p><strong>Location:</strong> {{ flagReportDetails.location ?? '-' }}</p>
            <p><strong>Name of the employee:</strong> {{ flagReportDetails.employee_name ?? '-' }}</p>
            <p><strong>Employee ID:</strong> {{ flagReportDetails.employee_id ?? '-' }}</p>
            <p><strong>Reason for {{flagReportDetails?.report_type}}:</strong> {{flagReportDetails.reason ?? '-'}}</p>
            <p><strong>Date of interview:</strong> {{flagReportDetails?.date ?? '-'}}</p>
            <p><strong>Recommendation:</strong> {{flagReportDetails.recommendation ?? '-'}}</p>
            <p><strong>AceNgage Counsellor name:</strong> {{flagReportDetails.counselor ?? '-'}}</p>
            <br>
        </div>
        <ng-container *ngIf="flagReportDetails.employee_id && this.id && this.key">
            <form [formGroup]="flagReportForm">
                <div class="form-wrap">
                    <h4>Notes *</h4>
                    <div class="data-wrap1">
                        <div class="each-data1">
                            <acengage-text-area [classesToAdd]="flagReportDetails?.is_active?'':'disabled'" formControlName="notes" [disabled]="!flagReportDetails?.is_active"></acengage-text-area>
                            <acengage-form-error [control]="flagReportForm.controls['notes']" validatorName="required">
                                Notes is required
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                
                <div class="form-wrap">
                    <h4>Resolution *</h4>
                    <div class="data-wrap1">
                        <div class="each-data1">
                            <acengage-text-area [classesToAdd]="flagReportDetails?.is_active?'':'disabled'" [disabled]="!flagReportDetails?.is_active" formControlName="resolution"></acengage-text-area>
                            <acengage-form-error [control]="flagReportForm.controls['resolution']" validatorName="required">
                                Resolution is required
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                <div class="button-section">
                    <div class="button">
                        <acengage-button buttonText="Submit" [isBtnDisable]="flagReportForm.invalid || !flagReportDetails?.is_active"
                            (buttonClicked)="submitFlagReport();">
                        </acengage-button>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</div>
<div class="popup" *ngIf="flagReportDetails.is_submitted">
    <acengage-flag-report-success></acengage-flag-report-success>
</div>
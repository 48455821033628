import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'acengage-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  displayModal: boolean;
  constructor() { }
  @Input() set openModel(value: boolean) {
    this.showModalDialog(value);
  }
  @Input() headerText = '';
  @Input() buttonDisable :boolean =false;
  @Input() confirmButtonText = '';
  @Input() cancelButtonText = '';
  @Input() skipButtonText = '';
  @Input() showFooterButtons = true;
  @Input() addCloseIcon: boolean = false;
  @Input() closable: boolean = false;
  @Input() classesToAdd = 'reduced_dialog_size';
  @Input() addBorderAboveBtns=false;
  @Input() nestQuestions:boolean=false;
  @Output() cancelButton = new EventEmitter<boolean>();
  @Output() confirmButton = new EventEmitter<boolean>();
  @Output() modelStateUpdate = new EventEmitter<boolean>();
  showModalDialog(value: boolean) {
    this.displayModal = value;
    this.modelStateUpdate.emit(value);
  }
  ngOnInit(): void { }

  confirmButtonClicked() {
    this.confirmButton.emit(true);
  }
  cancelButtonClicked() {
    this.cancelButton.emit(true);
  }
  onHide(event: any) {
    this.modelStateUpdate.emit(this.displayModal);
  }
}

import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { ValueAccessorService } from '../../shared-service/valueAccessor.service';
@Component({
  selector: 'acengage-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends ValueAccessorService {
  @Input() id = '';
  @Input() options: any;
  @Input() placeHolder: any = 'Select';
  @Input() optionValue: any = 'value';
  @Input() optionLabel: any = 'name';
  @Input() isMultiSelect = false;
  @Input() treeSelect = false;
  @Input() editable = false;
  @Input() treeMultiSelect = false;
  @Input() isShowClear = true;
  @Input() isShowFloatLabel = true;
  @Input() override disabled = false;
  @Input() filter = true;
  @Input() set searchBy(value: string) {
    this.filterBy = value || 'name';
  }
  @Input() lazy = false;
  @Input() virtualScroll = false;
  @Input() appendTo: string | null = null;
  @Input() classesToAdd = '';
  @Input() name = '';
  @Input() filterBy = '';
  @Input() lazyConfig: { page: number; limit?: number; search: string } = {
    page: 1,
    limit: 10,
    search: '',
  };
  @Output() getNextOptions = new EventEmitter<{
    page: number;
    search: string;
  }>();
  @Output() selectedValue = new EventEmitter();
  searchSubject$ = new Subject<string>();

  constructor() {
    super();
    // Subscribe to the searchSubject and debounce the input by 500ms
    this.searchSubject$
      .pipe(debounceTime(500))
      .subscribe((searchValue: string) => {
        this.lazyConfig = {
          ...this.lazyConfig,
          page: 1,
          search: searchValue || '',
        };
        this.getNextOptions.emit(this.lazyConfig);
        setTimeout(() => {
          this.virtualScroll = true;
        }, 700);
      });
  }

  onLazyLoad(event: any) {
    const { first, last } = event;
    const visibleOptions = (last - first + 1) * this.lazyConfig.page;
    const currentOptionsLength = this.options.length;
    if (visibleOptions === currentOptionsLength) {
      this.lazyConfig.page++;
      this.getNextOptions.emit(this.lazyConfig);
    }
  }

  onLazyFilter(event: any) {
    event?.originalEvent?.preventDefault();
    this.virtualScroll = false;
    this.searchSubject$.next(event?.filter || '');
  }

  onValUpdate(event: any) {
    const value = this.isMultiSelect ? event : event;
    this.selectedValue.emit(value);
  }
  handleEmptySpace(event: any) {
    if (event.which === 32 && !event?.target?.value) {
      event.preventDefault();
    }
  }
}

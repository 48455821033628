import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import WebViewer from '@pdftron/pdfjs-express'
import PSPDFKit from 'pspdfkit';
import { Store } from '@ngrx/store';
import * as AppActions from '@acengage/app/app/state/app.actions';

@Component({
  selector: 'acengage-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {
  documentLoaded: boolean = false;
  @Output() maxTime = new EventEmitter();

  @Input() url = 'http://www.pdf995.com/samples/pdf.pdf';
  constructor(private store: Store) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.documentLoaded) {
        // Show your popup here
        this.maxTime.emit(true);
      }
    }, 30000); // 10 seconds in milliseconds

    // const element = document.getElementById('viewer');
    // WebViewer({
    //   path: '/public', // point to where the files you copied are served from
    //   initialDoc: this.url // path to your document
    // }, element).then((instance:any) => {
    //   // Call APIs here
    // })
    // PSPDFKit.load({
    // 	// Use the assets directory URL as a base URL. PSPDFKit will download its library assets from here.
    // 	baseUrl: location.protocol + "//" + location.host + "/assets/",
    // 	document: this.url,
    // 	container: "#pspdfkit-container",
    // }).then(instance => {
    // 	// For the sake of this demo, store the PSPDFKit for Web instance
    // 	// on the global object so that you can open the dev tools and
    // 	// play with the PSPDFKit API.
    // 	(window as any).instance = instance;
    // });
  }

  showLoaderTrigger(isShow: boolean) {
    this.store.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
  showToaster(type: string, heading: string, message: string) {
    this.store.dispatch(
      AppActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: true,
      })
    );
  }
  contentLoaded() {
    this.documentLoaded = true;
    this.showLoaderTrigger(false);
  }
}

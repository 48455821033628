<div class="filter__table--container">
    <acengage-button [isNgContentAdded]="true" (buttonClicked)="filterButtonClick();" [isBtnDisable]="disableFilter"
        buttonClassesToAdd="p-button-outlined p-button-secondary" [buttonText]="'Filter (' + getFilteredCount()+')'">
        <img src="../../shared-assets/images/general_icons/filter_icon_primary.svg" alt="icon">
    </acengage-button>

    <div class="filter__table--content" *ngIf="isShowDropDown">
        <ng-container *ngIf="filterKeys.length">
            <div class="filter__table--content--body">
                <acengage-accordion [headers]="filterKeys" [filterDetails]="filterDetails" [filteredData]="filteredData"
                    (accordionOpen)="checkDataExists($event);">
                    <ng-template *ngFor="let eachKey of filterKeys" acengageTemplateMarker> 
                        <div *ngIf="filterDetails[eachKey]?.filter_type === 'Input'">
                            <acengage-text-input [(ngModel)]="filterDetails[eachKey].fieldSearched" [inputType]="'text'"
                                [isShowFloatLabel]="false" [placeholder]="'Search'"></acengage-text-input>
                        </div>
                        <div *ngIf="filterDetails[eachKey]?.filter_type === 'List'">
                            <ng-container *ngIf="filterDetails[eachKey]?.is_lazy else notLazyLoadList">
                                <acengage-multi-select-lazy [optionLabel]="'filter_name'" [optionValue]="'filter_id'"
                                    [options]="filterDetails[eachKey]?.data || []" [label]="'Select'"
                                    [placeHolder]="'Select'" [isShowFloatLabel]="false"
                                    [searchtext]="filterDetails[eachKey].searchTextValue"
                                    [(ngModel)]="filteredData[filterDetails[eachKey]['filter_key']]"
                                    [selectedID]="filteredData[filterDetails[eachKey]['filter_key']]"
                                    [lazyConfig]="filterDetails[eachKey]"
                                    (getNextOptions)="getLazyLoadNextData($event, eachKey);"></acengage-multi-select-lazy>
                            </ng-container>
                            <ng-template #notLazyLoadList>
                                <acengage-select [options]="filterDetails[eachKey]?.data || []" [appendTo]="'body'"
                                    [optionLabel]="'filter_name'" [optionValue]="'filter_id'" [filterBy]="'filter_name'"
                                    [(ngModel)]="filteredData[filterDetails[eachKey]['filter_key']]"
                                    [isMultiSelect]="true" [placeHolder]="'Select'" [editable]="true"
                                    [isShowFloatLabel]="false">
                                </acengage-select>
                            </ng-template>
                        </div>
                        <div *ngIf="filterDetails[eachKey]?.filter_type === 'Date'">
                            <div>
                                <acengage-select [options]="dateSelectOptions" [appendTo]="'body'"
                                    [(ngModel)]="selectedDateDtls[filterDetails[eachKey]['filter_key']].cond"
                                    [searchBy]="'name'" [placeHolder]="'Select'" [editable]="false"
                                    [isShowFloatLabel]="false" (ngModelChange)="selectDateRange(eachKey);">
                                </acengage-select>
                            </div> 
                            <div *ngIf="eachKey=='Created On' || eachKey=='Modified Date' || eachKey=='Modified On' || eachKey=='Closed On' || eachKey=='Created on' || eachKey=='Date Of Payment' || eachKey=='Date Of Invoice' || eachKey=='Modified on' || eachKey=='Date Of Debit Note'" > 
                                <acengage-date-picker [name]="filterDetails[eachKey]['filter_key']" [appendTo]="'body'"
                                    [(ngModel)]="selectedDateDtls[filterDetails[eachKey]['filter_key']].from_date"
                                    [placeholder]="selectedDateDtls[filterDetails[eachKey]['filter_key']].cond === 'range' ? 'From date' : 'Date'"
                                    [isShowFloatLabel]="false" [maxDate]="todayDate">
                                </acengage-date-picker>
                            </div>
                            <div *ngIf="eachKey!='Created On' && eachKey!='Modified Date' && eachKey!='Modified On' && eachKey!='Closed On' && eachKey!='Created on' && eachKey!='Modified on' && eachKey!='Date Of Payment' && eachKey!='Date Of Invoice' && eachKey!='Date Of Debit Note'"> 
                                <acengage-date-picker [name]="filterDetails[eachKey]['filter_key']" [appendTo]="'body'"
                                    [(ngModel)]="selectedDateDtls[filterDetails[eachKey]['filter_key']].from_date"
                                    [placeholder]="selectedDateDtls[filterDetails[eachKey]['filter_key']].cond === 'range' ? 'From date' : 'Date'"
                                    [isShowFloatLabel]="false">
                                </acengage-date-picker>
                            </div>


                            <div *ngIf="selectedDateDtls[filterDetails[eachKey]['filter_key']].cond === 'range'">
                                <acengage-date-picker [name]="filterDetails[eachKey]['filter_key']" [appendTo]="'body'"
                                    [(ngModel)]="selectedDateDtls[filterDetails[eachKey]['filter_key']].to_date"
                                    [placeholder]="'To date'"
                                    [minDate]="selectedDateDtls[filterDetails[eachKey]['filter_key']].from_date"
                                    [disabled]="!selectedDateDtls[filterDetails[eachKey]['filter_key']].from_date"
                                    [isShowFloatLabel]="false">
                                </acengage-date-picker>
                            </div>
                        </div>
                        <div
                            *ngIf="filterDetails[eachKey]?.filter_type === 'Number' || filterDetails[eachKey]?.filter_type === 'Decimal'">
                            <div>
                                <acengage-select [options]="NumberListOption" [appendTo]="'body'"
                                    [(ngModel)]="selectedNumberDetails[filterDetails[eachKey]['filter_key']].cond"
                                    [searchBy]="'name'" [placeHolder]="'select'" [editable]="false"
                                    [isShowFloatLabel]="false" (ngModelChange)="selectNumber(eachKey);">
                                </acengage-select>
                            </div>
                            <div>
                                <acengage-text-input
                                    [(ngModel)]="selectedNumberDetails[filterDetails[eachKey]['filter_key']].from_number"
                                    [inputType]="'text'"
                                    [allowedKeys]="filterDetails[eachKey]?.filter_type === 'Decimal' ? 'num' : numRegex"
                                    [isShowFloatLabel]="false"
                                    [placeholder]="selectedNumberDetails[filterDetails[eachKey]['filter_key']].cond === 'range' ? 'Start Range' : 'Value'"></acengage-text-input>
                            </div>
                            <div *ngIf="selectedNumberDetails[filterDetails[eachKey]['filter_key']].cond === 'range'">
                                <acengage-text-input
                                    [(ngModel)]="selectedNumberDetails[filterDetails[eachKey]['filter_key']].to_number"
                                    [inputType]="'text'"
                                    [allowedKeys]="filterDetails[eachKey]?.filter_type === 'Decimal' ? 'num' : numRegex"
                                    [isShowFloatLabel]="false"
                                    [disabled]="!selectedNumberDetails[filterDetails[eachKey]['filter_key']].from_number"
                                    [placeholder]="'End Range'"></acengage-text-input>

                            </div>
                        </div>
                    </ng-template>
                </acengage-accordion>
            </div>
            <div class="filter__table--content--footer">
                <acengage-button buttonText="Clear" [buttonClassesToAdd]="'secondary-btn'"
                    (buttonClicked)="closeFilter();"></acengage-button>
                <acengage-button buttonText="Apply" (buttonClicked)="updateFilterSelection();"></acengage-button>
            </div>
        </ng-container>
        <p *ngIf="!filterKeys.length">No filter options.</p>
    </div>
</div>

<acengage-loader [showLoader]="isShowLoader"></acengage-loader>
import {
  Component,
  OnInit,
  ViewEncapsulation,
  forwardRef,
  Input,
  ElementRef,
  AfterViewInit,
  AfterContentChecked,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements ControlValueAccessor , AfterContentChecked {

  value: any;
  disabled = false;
  feedback = true;
  @Input() showSuggessions=false;
  maximumLength: string = '';
  @Input() placeholder: string = '';
  @Input() set showPasswordStrength(value: any) {
    this.feedback = value;
  }
  @Input() set maxLength(value: any) {
    this.maximumLength = value
  }

  
  private valueChanges = new Subject<string>();
  private touches = new Subject();
  onChange = () => {};
  onTouch = () => {};
  private domElement: HTMLElement;
      constructor( private elementRef: ElementRef) {
        // get ref HTML element
        this.domElement = this.elementRef.nativeElement as HTMLElement;
    }
  public ngAfterContentChecked(): void {

    const input = this.domElement.getElementsByTagName('input') ;

    if (input && input.length > 0) {
        (input[0] as HTMLElement).setAttribute('maxlength', this.maximumLength.toString());
    }
}

  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value;
  }
  updateValue(value: any) {
    this.value = value;
    this.valueChanges.next(value);
  }
  touch() {
    this.touches.next(null);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-slot-confirm-success',
  templateUrl: './slot-confirm-success.component.html',
  styleUrls: ['./slot-confirm-success.component.scss'],
})
export class SlotConfirmSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private http: HttpClient) {}

  getUserIndDetails() {
    return this.http.get(`${environment.api_url}/user/api/profile/`);
  }

  updtBasicInfo(basicInfoPayload: FormData) {
    return this.http.put(
      `${environment.api_url}/user/api/profile/`,
      basicInfoPayload
    );
  }

  getSubscription() {
    return this.http.get(`${environment.api_url}/account/profile/subscription`);
  }
}

<router-outlet></router-outlet>

<acengage-toaster
  toasterPosition="top-right"
  [toasterInfos]="toasterInfo"
  [displaytoaster]="displayToast"
  (hideToaster)="hideToaster($event)"
>
</acengage-toaster>
<acengage-loader [showLoader]="showLoader"></acengage-loader>


<acengage-dialog
  [openModel]="showConfirmationDialog"
  [headerText]="'Update Profile'"
  [confirmButtonText]="'Continue'"
  [cancelButtonText]="'Cancel'"
  (confirmButton)="updtStatusOfUsers('edit')"
  (cancelButton)="updtStatusOfUsers('skip')"
>
  <p>{{ confimationMessage }}</p>
</acengage-dialog>

<acengage-dialog [classesToAdd]="'reduced_dialog_size'" [openModel]="showInitiateCallDialog"
[headerText]="''" [addCloseIcon]="(iconIndex !== 0 && iconIndex !== 3 && iconIndex !== 5 && iconIndex !== 6 && iconIndex !== 100)?true:false"
(cancelButton)="showInitiateCallDialog = false;" (modelStateUpdate)="closeModal($event)">
<h3 style="text-align: center;text-transform: uppercase;font-size:1rem;margin-top: 0;color: var(--blue-300);" class="caller-text" *ngIf="ServiceName && isOutgoingCall">{{ServiceName}}</h3>
<div class="ca__profile__creation--popup" >
  <div class="ca__profile__creation--popup-cntr">
    <div class="caller-content">
      <div class="caller-head">
        <div class="caller-icon" [ngClass]="iconItems[iconIndex]?.className"></div>
        <div class="caller-text">{{isCallerError?isCallerError:callerMessage}}</div>
      </div>
      <div class="caller-body">
        <div class="caller-dotted-icon-outer">
          <div class="caller-dotted-icon"></div>
        </div>
        <div class="caller-wrapper" [ngClass]="{'wrap-reverse' : !isOutgoingCall}">
          <div class="caller-user">
            <div class="caller-user-avatar left">
              <!-- {{loggedInUserDtls?.userDetails?.personal_info?.first_name[0] ?? '-'}} -->
            </div>
            <h4 class="caller-user-name marg_0">Smartflo
              <!-- {{loggedInUserDtls?.userDetails?.personal_info?.first_name ?? '-'}} -->
            </h4>
            <!-- <p class="caller-user-id marg_0">{{loggedInUserDtls?.userDetails?.personal_info?.mobile_number ?? '-'}}</p> -->
            <!-- <img src="assets/images/smartflo.jpg" alt="call icon" /> -->
          </div>
          <div class="caller-user">
            <div class="caller-user-avatar" style="text-transform: uppercase;">{{callerModal.first_name[0]}}</div>
            <h4 class="caller-user-name marg_0">{{callerModal.first_name ?? '-'}}</h4>
            <p class="caller-user-id marg_0">{{callerModal.mobile ?? '-'}}</p>
          </div>
        </div>
      </div>
      <div class="caller-body-reminder" *ngIf="dialedCandidate?.status">
        <div class="caller-reminder" style="width: 100% !important;" *ngIf="dialedCandidate?.status === 5">
          <div class="caller-userreminder special" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Status</p>
            <h4 class="caller-userreminder-name marg_0">{{dialedCandidate?.nc_data?.status ?? '-'}}</h4>
            <!-- <img src="assets/images/smartflo.jpg" alt="call icon" /> -->
          </div>
          <div class="caller-userreminder" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Notes</p>
            <h4 class="caller-userreminder-name marg_0">{{dialedCandidate?.nc_data?.notes ?? '-'}}</h4>
          </div>
        </div>
        <div class="caller-reminder" style="width: 100% !important;justify-content: center;" *ngIf="dialedCandidate?.status !== 5">
          <div class="caller-userreminder" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Status</p>
            <h4 class="caller-userreminder-name marg_0">Pending</h4>
            <!-- <img src="assets/images/smartflo.jpg" alt="call icon" /> -->
          </div>
        </div>
      </div>
      <div class="caller-body-reminder" *ngIf="dialedCandidate?.campaign_name && dialedCandidate?.intervention_name">
        <div class="caller-reminder" style="width: 100% !important;">
          <div class="caller-userreminder special" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Campaign</p>
            <h4 class="caller-userreminder-name marg_0">{{dialedCandidate?.campaign_name ?? '-'}}</h4>
            <!-- <img src="assets/images/smartflo.jpg" alt="call icon" /> -->
          </div>
          <div class="caller-userreminder" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Intervention</p>
            <h4 class="caller-userreminder-name marg_0">{{dialedCandidate?.intervention_name ?? '-'}}</h4>
          </div>
        </div>
      </div>
      <div class="caller-button" *ngIf="isOutgoingCall">
          <acengage-button [buttonText]="'Start Interview'" buttonClassesToAdd="secondary-btn" *ngIf="iconIndex === 3"
          [iconDtls]="{ name: 'pi pi-arrow-right', position: 'right' }" (buttonClicked)="rowSelection(dialedCandidate)"></acengage-button>
          <acengage-button [buttonText]="'Update NC Status'" buttonClassesToAdd="secondary-btn" *ngIf="outgoingError"
          [iconDtls]="{ name: 'pi pi-arrow-right', position: 'right' }" (buttonClicked)="rowSelection(dialedCandidate)"></acengage-button>
      </div>
    </div>
  </div>
</div>
</acengage-dialog>
<acengage-dialog [classesToAdd]="'reduced_dialog_size'" [openModel]="showFlagReportDialog"
[headerText]="''" [addCloseIcon]="true"
(cancelButton)="showInitiateCallDialog = false;" (modelStateUpdate)="closeModal($event)">
<p style="text-align: center;text-transform: uppercase;font-size:1rem;margin-top: 0;" class="caller-text">
  {{flagReport?.type==='flag_report_counselor_notification'?'Please confirm and forward the ' + flagReport?.report_type +' flag report to the client as requested by the counselor.':'The client has made changes to the ' + flagReport?.report_type +' flag report below.'}}
</p>
<div class="ca__profile__creation--popup" >
  <div class="ca__profile__creation--popup-cntr">
    <div class="caller-content">
      <div class="caller-body-reminder">
        <div class="caller-reminder" style="width: 100% !important;">
          <div class="caller-userreminder special" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Client</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.client ?? '-'}}</h4>
          </div>
          <div class="caller-userreminder" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Location</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.location ?? '-'}}</h4>
          </div>
        </div>
        <div class="caller-reminder" style="width: 100% !important;">
          <div class="caller-userreminder special" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Name of the employee</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.employee_name ?? '-'}}</h4>
          </div>
          <div class="caller-userreminder" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Employee ID</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.employee_id ?? '-'}}</h4>
          </div>
        </div>
        <div class="caller-reminder" style="width: 100% !important;">
          <div class="caller-userreminder special" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Reason</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.reason ?? '-'}}</h4>
          </div>
          <div class="caller-userreminder" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Date of interview</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.date ?? '-'}}</h4>
          </div>
        </div>
        <div class="caller-reminder" style="width: 100% !important;">
          <div class="caller-userreminder special" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Intervention</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.intervention ?? '-'}}</h4>
          </div>
          <div class="caller-userreminder" style="width: 50% !important;text-align: center;">
            <p class="caller-userreminder-id marg_0">Counselor</p>
            <h4 class="caller-userreminder-name marg_0">{{flagReport?.counselor ?? '-'}}</h4>
          </div>
        </div>
      </div>
      <div class="caller-button">
          <acengage-button [buttonText]="'Okay'" buttonClassesToAdd="secondary-btn"
          [iconDtls]="{ name: 'pi pi-arrow-right', position: 'right' }" (buttonClicked)="viewFlagReport(flagReport)"></acengage-button>
      </div>
    </div>
  </div>
</div>
</acengage-dialog>
<div class="p-msg-outer" [ngStyle]="{'visibility': showInitiateReminder ? 'visible' : 'hidden'}">
  <p-messages
  [enableService]="false" [(value)]="reminder"
  [showTransitionOptions]="'300ms ease-out'" 
  [hideTransitionOptions]="'200ms cubic-bezier(0.86, 0, 0.07, 1)'">
    <ng-template pTemplate>
        <img src="../assets/images/notif-red.svg" width="32" />
        <div class="caller-content" (click)="rowSelection(reminder)" style="cursor: pointer;">
          <div class="caller-body-reminder">
            <div class="caller-reminder">
              <div class="caller-userreminder special">
                <p class="caller-userreminder-id marg_0">Candidate Name:</p>
                <h4 class="caller-userreminder-name marg_0">{{reminder.employee_name ?? '-'}}</h4>
                <!-- <img src="assets/images/smartflo.jpg" alt="call icon" /> -->
              </div>
              <div class="caller-userreminder">
                <p class="caller-userreminder-id marg_0">Campaign:</p>
                <h4 class="caller-userreminder-name marg_0">{{reminder.campaign_name ?? '-'}}</h4>
              </div>
            </div>
            <div class="caller-reminder" style="justify-content: end;margin-left:1rem;">
              <div class="caller-userreminder">
                <p class="caller-userreminder-id marg_0">Call Back Time:</p>
                <h4 class="caller-userreminder-name marg_0">{{reminder.time ?? '-'}}</h4>
                <!-- <img src="assets/images/smartflo.jpg" alt="call icon" /> -->
              </div>
            </div>
          </div>
        </div>
        <img src="../assets/images/close-round-gray.svg" width="22.8" style="cursor: pointer;" (click)="closeReminder()" title="Close" />
    </ng-template>
  </p-messages>
</div>

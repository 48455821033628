<div class="header">
    <acengage-logo></acengage-logo>
</div>
<div class="content-wrap" *ngIf="!candidateDtls.is_submitted">
    <h2>Welcome {{ (candidateDtls.stakeholder_name | titlecase) || candidateDtls.email }}</h2>
    <h3>Kindly provide slots to the below connects.</h3>
    <div class="email-content">
        <div class="each-data-wrap">
            <div class="each-data">
                <h4>Candidate Name</h4>
                <h5>{{candidateDtls.candidate_name}}</h5>
            </div>
            <div class="each-data">
                <h4>Candidate ID</h4>
                <h5>{{candidateDtls.candidate_id}}</h5>
            </div>
            <div class="each-data">
                <h4>Date of Joining</h4>
                <h5>{{candidateDtls.date_of_join | date: 'dd/MM/YYYY'}}</h5>
            </div>

            <div class="each-data">
                <h4>{{getRecruiterKeyName() | titlecase}} Name</h4>
                <h5>{{candidateDtls.stakeholder_name || '-'}}</h5>
            </div>
            <div class="each-data">
                <h4>Location</h4>
                <h5>{{candidateDtls.location || '-'}}</h5>
            </div>
            <div class="each-data">
                <h4>BU/Tower/Dept</h4>
                <h5>{{candidateDtls.department || '-'}}</h5>
            </div>
        </div>
        <ng-container *ngIf="slotSelectionForm">
            <form [formGroup]="slotSelectionForm">
                <div class="form-wrap">
                    <h4>Select Slot 1</h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_date_1" [minDate]="todayDate"
                                [placeholder]="'Select Date *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls.slot_date_1"
                                validatorName="required">
                                1st slot date is required
                            </acengage-form-error>
                        </div>
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_time_1" [icon]="'pi pi-clock'" [timeOnly]="true"
                                [showSeconds]="true" [placeholder]="'Select Time (IST) *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls.slot_time_1"
                                validatorName="required">
                                1st slot time is required
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                <div class="form-wrap">
                    <h4>Select Slot 2</h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_date_2" [minDate]="todayDate"
                                [placeholder]="'Select Date *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls.slot_date_2"
                                validatorName="required">
                                2nd slot date is required
                            </acengage-form-error>
                        </div>
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_time_2" [icon]="'pi pi-clock'" [timeOnly]="true"
                                [showSeconds]="true" [placeholder]="'Select Time (IST) *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls.slot_time_2"
                                validatorName="required">
                                2nd slot time is required
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                <div class="form-wrap">
                    <h4>Select Slot 3</h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_date_3" [minDate]="todayDate"
                                [placeholder]="'Select Date *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls.slot_date_3"
                                validatorName="required">
                                3rd slot date is required
                            </acengage-form-error>
                        </div>
                        <div class="each-data">
                            <acengage-date-picker formControlName="slot_time_3" [icon]="'pi pi-clock'" [timeOnly]="true"
                                [showSeconds]="true" [placeholder]="'Select Time (IST) *'">
                            </acengage-date-picker>
                            <acengage-form-error [control]="slotSelectionForm.controls.slot_time_3"
                                validatorName="required">
                                3rd slot time is required
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                <div class="form-wrap">
                    <h4>Add Meeting Link</h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-text-input formControlName="link"
                                [placeholder]="(candidateDtls.type === 1) ? 'Add Meeting Link' : 'Add Meeting Link *'">
                            </acengage-text-input>
                            <acengage-form-error [control]="slotSelectionForm.controls.link" validatorName="required">
                                Meeting link is required
                            </acengage-form-error>
                            <acengage-form-error [control]="slotSelectionForm.controls.link" validatorName="pattern">
                                Invalid meeting link
                            </acengage-form-error>
                        </div>
                    </div>
                </div>
                <div class="form-wrap">
                    <h4>Meeting ID</h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-text-input formControlName="meeting_id" [placeholder]="'Meeting ID'">
                            </acengage-text-input>
                        </div>
                    </div>
                </div>
                <div class="form-wrap">
                    <h4>Passcode</h4>
                    <div class="data-wrap">
                        <div class="each-data">
                            <acengage-text-input formControlName="passcode" [placeholder]="'Passcode'"></acengage-text-input>
                        </div>
                    </div>
                </div>
                <div class="button-section">
                    <div class="button">
                        <acengage-button buttonText="Submit" [isBtnDisable]="slotSelectionForm.invalid"
                            (buttonClicked)="submitSlotSelection();">
                        </acengage-button>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</div>
<div class="popup" *ngIf="candidateDtls.is_submitted">
    <acengage-slot-selection-success></acengage-slot-selection-success>
</div>
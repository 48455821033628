import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ForgotOTPPayload, sixDigitOtp } from '@acengage/app/app/authentication/models/login.model';
import { getForgot } from '@acengage/app/app/authentication/state/auth.selectors';
import * as AuthActions from '@acengage/app/app/authentication/state/auth.actions';
import { Config } from '@acengage/app/app/config';

@Component({
  selector: 'acengage-forgot-password-step-two',
  templateUrl: './forgot-password-step-two.component.html',
  styleUrls: ['./forgot-password-step-two.component.scss'],
})
export class ForgotPasswordStepTwoComponent implements OnInit {
  sixDigitOtp: FormGroup<sixDigitOtp>;
  loginStoreValue$: Observable<any>;
  toasterInfoObj = {
    severity: 'success',
    summary: '',
    detail: '',
  };
  numRegex = Config.numberPolicy;
  countTime: any = 30;
  minutes: any;
  seconds: any;
  interval: any;
  userDtl: string | null;
  constructor(private router: Router, private fb: FormBuilder, private store: Store) { }
  ngOnInit(): void {
    this.store.select(getForgot).subscribe((res) => {
      this.userDtl = res.data;
    });
    this.createSixDigitform();
    this.countDown();
  }
  createSixDigitform(): void {
    this.sixDigitOtp = this.fb.group({
      otp1: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp2: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp3: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp4: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp5: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp6: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
  }

  enterOtp(event: any, prev: any, curr: any, next: any) {
    if (event.key == 'Backspace') {
      if (prev != '') {
        prev.element.nativeElement.childNodes[0].firstElementChild.focus();
      }
    }
    const length = curr.value.length;
    const maxlength = event.currentTarget.getAttribute('maxlength');

    if (length == maxlength) {
      if (next != '') {
        next.element.nativeElement.childNodes[0].firstElementChild.focus();
      }
    }


  }
  countDown() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    const min = localStorage.getItem('minutes')
    if (Number(min)) {
      this.countTime = Number(min)
    }
    const maxTime = new Date(new Date().setMinutes(new Date().getMinutes() + this.countTime)).getTime();
    this.interval = setInterval(() => {
      const now = new Date().getTime()
      const distance = maxTime - now;
      const minutes = Math.floor(((distance % (1000 * 60 * 60)) / (1000 * 60)));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.minutes = minutes < 10 ? '0' + minutes : minutes;
      this.seconds = seconds < 10 ? '0' + seconds : seconds;
      if (distance < 0) {
        clearInterval(this.interval);
        // this.expired.emit(true);
        this.minutes = this.seconds = '00';
      }
    }, 1000);
  }
  resentOtp() {
    if (this.minutes == 0 && this.seconds == 0) {
      localStorage.removeItem('minutes')
      const payload = {
        username: this.userDtl
      }
      this.store.dispatch(AuthActions.ForgotPasswordReq({ payload: payload }))
      this.sixDigitOtp.reset()
      this.countDown();
    }
  }
  submitUsername() {
    if (
      this.sixDigitOtp.controls['otp1'].value != null &&
      this.sixDigitOtp.controls['otp2'].value != null &&
      this.sixDigitOtp.controls['otp3'].value != null &&
      this.sixDigitOtp.controls['otp4'].value != null &&
      this.sixDigitOtp.controls['otp5'].value != null &&
      this.sixDigitOtp.controls['otp6'].value != null
    ) {
      const otp = this.sixDigitOtp.controls['otp1'].value +
        this.sixDigitOtp.controls['otp2'].value +
        this.sixDigitOtp.controls['otp3'].value +
        this.sixDigitOtp.controls['otp4'].value +
        this.sixDigitOtp.controls['otp5'].value +
        this.sixDigitOtp.controls['otp6'].value;
      const otpParams = {
        username: this.userDtl,
        otp: otp
      }
      this.store.dispatch(AuthActions.ForgotOTPReq({ payload: otpParams }))
      this.sixDigitOtp.reset()
      localStorage.removeItem('minutes')
    }
  }
  ngOnDestroy() {
    if (this.seconds !== '00' || this.minutes !== '00') {
      localStorage.setItem('minutes', this.minutes)
    }
  }
}

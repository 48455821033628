
<div class="btn-group">
    <div class="heading">
      <h2>Tata Dialer Recordings</h2>
    </div>
    <div class="btn-group" *ngIf="isBackBtn">
      <acengage-button buttonText="Go Back" [classToadd]="'p-button-outlined p-button-secondary'"
                (buttonClicked)="goBack.emit()" [iconDtls]="iconDtls">
            </acengage-button>
    </div>
  </div>
<div class="ce-history-table">
    <acengage-table [currentModule]="'CE'" [tableRecords]="viewHistoryList" [headerlabels]="headers"
    (pageChange)="getNextpageRecords($event)" [checkboxes]="false" [showActions]="false" [isShowEditColumns]="false"
    (filterTriggered)="filterHistory($event)" (sortTriggered)="sortColumn($event);" (audioDownload)="audioDownload($event)" [isShowFilter]="false" [isShowSearch]="false" [total]="totalCount" [tableLoader]="loading"
    >
   </acengage-table>
</div>

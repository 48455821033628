<form [formGroup]="questionForm">
    <div class="add__question--left--content">
        <acengage-text-input [inputType]="'text'" [maxLength]="null"
            [placeholder]="'Enter the question here *'" formControlName="question">
        </acengage-text-input>
        <acengage-form-error [control]="questionForm.controls['question']" validatorName="required">
            Question is required.
        </acengage-form-error>
    </div>
    <!-- <acengage-choice-question-type [questionForm]="questionForm"
    (addClicked)="addCheckboxOptionClicked.emit();"
    (removeClicked)="removeCheckboxOptionClicked.emit($event);"
    (addFollowUp)="addFollowUpClicked.emit($event);"> -->
    <div formArrayName="options">
        <div *ngFor="let eachOption of checkboxAnswerFA().controls; let i = index"
            class="add__question--input--container">
            <div [formGroupName]="i" class="add__question--left--content answer__content--input">
                <div class="checkbox__with__answer">
                    <acengage-checkbox-input [ngClass]="{'custom_rounded_checkbox': isMultiChoice}" [disabled]="true">
                    </acengage-checkbox-input>
                    <div class="input__container">
                        <acengage-text-input [maxLength]="null" (ngModelChange)="questionForm.updateValueAndValidity();"
                            formControlName="choice" [placeholder]="'Option '+(i+1)+' *'" [isShowFloatLabel]="false" required="required">
                        </acengage-text-input>
                        <acengage-form-error [control]="eachOption.controls.choice" validatorName="required">
                            Option {{i+1}} is required.
                        </acengage-form-error>
                    </div>
                </div>
            </div>
            <div *ngIf="checkboxAnswerFA().controls.length > 2" class="add__question--right--content">
                <div class="add__question--link remove" (click)="removeOptions(i);">
                    <i class="pi pi-trash"></i>
                    <span>Remove</span>
                </div>
            </div>
        </div>
    </div>
<!-- </acengage-choice-question-type> -->

</form>
<div class="add__question--link add" (click)="addMoreOptions();">
    <i class="pi pi-plus"></i>
    <span>Add Options</span>
</div>
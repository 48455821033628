import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AcengageTreeForm } from '../../acengage-tree/models/add-acengage-tree.interface';
import { AcengageTreeService } from '../../services/acengage-tree.service';
import { AppState } from '../../state/app.state';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { SelectOption } from '../../models/select.model';

@Component({
  selector: 'acengage-add-edit-tree-view',
  templateUrl: './add-edit-tree-view.component.html',
  styleUrls: ['./add-edit-tree-view.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AddEditTreeViewComponent implements OnInit {
  constructor(private treeService: AcengageTreeService,
    private appStore: Store<AppState>) { }
  @Input() isShowServices = true;
  @Input() serviceId:any;
  @Input() nest:boolean=false;
  @Input() treeForm = {} as AcengageTreeForm;
  @Input() treeFormValidation: NgForm;
  @Input() processType: string | null = '';
  @Output() addClicked = new EventEmitter<string | null>();
  @Output() resetClicked = new EventEmitter<null>();
  addIcon = {
    name: 'pi pi-plus',
    position: 'left',
  };
  treeNest=false;
  @Input() mappingFields: SelectOption[] = [];

  ngOnInit(): void { }

  removeOption(removeableIndex: number) {
    this.treeForm.options?.splice(removeableIndex, 1);
    this.treeForm.options = [...this.treeForm.options];
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'acengage-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
})
export class FileuploadComponent implements OnInit {
  uploadedFiles: any[] = [];
  @Input() isMultiUpload = true;
  @Input() maxFileSize = 2000000;
  @Input() name = 'filename';
  @Input() acceptType = 'image/*';
  @Input() removeAcceptText:boolean = false;
  @Input() btnLabel = 'Upload';
  @Input() btnIcon = 'pi pi-upload';
  @Input() showCancelButton = true;
  @Input() deleteFile = false;
  @Input() clientData = false;
  @Input() showFileUploadInfo = true;
  @Output() uploadedData = new EventEmitter();
  @Output() deleteUploadFile = new EventEmitter();
  @Output() validationToaster = new EventEmitter<string>();
  @Input() existingFiles: File[];
  @Input() disableUpload = false;
  @ViewChild('multipleFileUpload') multipleFileUpload: FileUpload;
  @Input() hideClearButton = false
  constructor(private messageService: MessageService) {}

  ngOnInit(): void {}

  onMultiUpload(event: any) {
    let fileErrMsg = '';
    for (const file of event.files) {
      const fileExtn: string =
        file?.name?.split('.')?.pop()?.toLowerCase() || '';
      if (file.size < this.maxFileSize && this.acceptType?.includes(fileExtn)) {
        this.uploadedFiles.push(file);
      } else {
        if (!this.acceptType.includes(fileExtn)) {
          fileErrMsg = `${fileErrMsg}${file.name} - Invalid file type\n`;
        } else {
          fileErrMsg = `${fileErrMsg}${file.name} - Invalid file size\n`;
        }
      }
    }
    this.uploadedFiles = [...new Set([...this.uploadedFiles, ...this.existingFiles])];
    this.uploadedData.emit(this.uploadedFiles);
    this.multipleFileUpload._files = [
      ...this.existingFiles,
      ...this.uploadedFiles,
    ];
    if (fileErrMsg) {
      fileErrMsg = `${fileErrMsg}\n\nNote:\nMaximum upload size is ${this.formatBytes(
        this.maxFileSize
      )}\nAllowed file types: ${this.acceptType}`;
      this.validationToaster.emit(fileErrMsg);
    }
  }

  onSingleUpload(event: any) {
    this.uploadedFiles = [];
    const selectedFile: File = event?.currentFiles?.length
      ? event.currentFiles[0]
      : event?.files[0];
    const fileExtn: string =
      selectedFile?.name?.split('.')?.pop()?.toLowerCase() || '';
    if (
      selectedFile.size < this.maxFileSize &&
      this.acceptType?.includes(fileExtn)
    ) {
      this.uploadedFiles.push(selectedFile);
      this.uploadedData.emit(this.uploadedFiles);
    } else {
      if (!this.acceptType.includes(fileExtn)) {
        this.validationToaster.emit(
          `Invalid file type, allowed file types: ${this.acceptType}.`
        );
      } else {
        this.validationToaster.emit(
          `Invalid file size, maximum upload size is ${this.formatBytes(
            this.maxFileSize
          )}.`
        );
      }
      this.existingFiles = [...this.existingFiles];
      this.uploadedData.emit(null);
    }
  }
  onUpload(event: UploadEvent) {
    this.uploadedData.emit(event);
  }
  clearFile(event: any) {
    console.log(event)
    if (this.deleteFile) {
      this.deleteUploadFile.emit(event);
    }
    if (event?.file) {
      this.uploadedFiles = this.uploadedFiles.filter(
        (eachFile) => eachFile !== event?.file
      );
      this.existingFiles =
        this.existingFiles?.filter(
          (eachExistFile) => eachExistFile !== event.file
        ) || [];
    } else {
      this.uploadedFiles = [];
    }
    this.uploadedData.emit(this.uploadedFiles);
  }
  clearFileMultiple(event: any) {
    console.log(event)
    if (this.deleteFile) {
      this.deleteUploadFile.emit(event);
    }
    if (event?.file) {
      const combinedFiles = [...new Set([...this.uploadedFiles, ...this.existingFiles])];
      const updatedUploadedFiles = combinedFiles.filter(eachFile => eachFile !== event?.file);
      this.uploadedFiles = updatedUploadedFiles;
    } else {
      this.uploadedFiles = [];
    }
    this.uploadedData.emit(this.uploadedFiles);
  }
  onBasicUpload(event: Event) {}

  formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-flag-report-success',
  templateUrl: './flag-report-success.component.html',
  styleUrls: ['./flag-report-success.component.scss'],
})
export class FlagReportSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor() {}

  @Input() classesToAdd = '';

  ngOnInit(): void {}
}

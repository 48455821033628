import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'acengage-permission-toggle-on-off',
  templateUrl: './permission-toggle-on-off.component.html',
  styleUrls: ['./permission-toggle-on-off.component.scss'],
})
export class PermissionToggleOnOffComponent implements OnInit {
  constructor() { }
  @Input() permissionHeading: string = '';
  @Input() permissionDesc: string = '';

  ngOnInit(): void { }
}

<div class="forgot-cntr">
  <div class="forgot-head">
    Create a new password
  </div>
  <form >
    <div class="forgot-input">
      <div class="forgot-input-content">
        <div class="marginBottom10px">
          <label>New password</label>
        </div>
        <div class="marginBottom10px">
          <acengage-password-input
          [maxLength]="'25'"
            [(ngModel)]="newPassword" (keydown.space)="$event.preventDefault()"
            name="newPass"
            [placeholder]="'New Password'"
            [showSuggessions]="true"
          >
        </acengage-password-input>
        </div>
      </div>
      <div class="forgot-input-content">
        <div class="marginBottom10px">
          <label>Confirm password</label>
        </div>
        <div class="marginBottom20px">
          <acengage-password-input
            [(ngModel)]="confirmNewPassword" (keydown.space)="$event.preventDefault()"
            [maxLength]="'25'"
            name="confirmPass"
            [placeholder]="'Re-enter Password'"
          ></acengage-password-input>
        </div>
      </div>
    </div>
    <div class="forgot-btn">
      <acengage-button (buttonClicked)="setNewpassword()"
        buttonText="Confirm"
      ></acengage-button>
    </div>
  </form>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'acengage-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  constructor() { }
  headers = [{
    name: 'tab 1',
    content: "<p>test1</p>"
  },
  {
    name: 'tab 2',
    content: "<p>test2</p>"
  },
  {
    name: 'tab 3',
    content: "<p>test3</p>"
  }];
  @Input() set tabHeaders(value: any) {
    this.headers = value;
  }

  ngOnInit(): void { }
  detectTabClick(event: any) {
    alert(JSON.stringify(event));
  }
}

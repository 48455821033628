import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-six-digit-otp',
  templateUrl: './six-digit-otp.component.html',
  styleUrls: ['./six-digit-otp.component.scss'],
})
export class SixDigitOtpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

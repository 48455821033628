import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'acengage-vertical-tabs',
  templateUrl: './vertical-tabs.component.html',
  styleUrls: ['./vertical-tabs.component.scss'],
})
export class VerticalTabsComponent implements OnInit {
  @Input() selectedTab = 1;
  @Input() tabsList: MenuItem[] = [];
  @Output() tabChanged = new EventEmitter<number>();
  constructor() {}

  ngOnInit(): void {}
}

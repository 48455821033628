

      <div class="btn-group">
        <div class="heading">
          <h2>Add Flag Report</h2>
        </div>
        <div class="btn-group" *ngIf="isBackBtn">
          <acengage-button buttonText="Go Back" [classToadd]="'p-button-outlined p-button-secondary'"
                    (buttonClicked)="goBack.emit();report_type='';" [iconDtls]="iconDtls">
                </acengage-button>
        </div>
      </div>
    <div class="ce-history-table">
        <div class="add-query--container">
            <div class="add-query--content" *ngIf="addFlagReportForm">
              <form [formGroup]="addFlagReportForm">
                <div class="add-query-input">
                  <div class="add-query-input-content select">
                    <acengage-select (ngModelChange)="selectedReport($event)" [disabled]="false" [options]="reportTypes" formControlName="reportType"
                      [placeHolder]="'Select Report Type *'"></acengage-select>
                    <div class="error" *ngIf="addFlagReportForm.controls['reportType'].errors && 
            addFlagReportForm.controls['reportType'].errors['required'] && isreportTypeTouched">
                      Report Type is required.
                    </div>
                  </div>
                  <div class="add-query-input-content select">
                    <acengage-text-input formControlName="location" [inputType]="'text'" [maxLength]="150"
                        [placeholder]="'Location *'">
                    </acengage-text-input>
                    <div class="error" *ngIf="addFlagReportForm.get('location')?.invalid && addFlagReportForm.get('location')?.touched">
                      Location is required.
                    </div>
                  </div>
                  <div class="add-query-input-content select">
                    <acengage-text-input formControlName="reason" [inputType]="'text'" [maxLength]="150"
                        [placeholder]="'Reason *'">
                    </acengage-text-input>
                    <div class="error" *ngIf="addFlagReportForm.get('reason')?.invalid && addFlagReportForm.get('reason')?.touched">
                      Reason is required.
                    </div>
                  </div>
                  <div class="add-query-input-content text-area desc">
                    <!-- <acengage-text-area [allowHTMLTags]="true" [placeholder]="'Enter Description *'" formControlName="description"></acengage-text-area>
                    <div class="error" *ngIf="addFlagReportForm.get('description')?.invalid && addFlagReportForm.get('description')?.touched">
                      Description is required.
                    </div> -->
                    <label class="edt_label">Description *</label>
                    <p-editor
                      formControlName="description"
                      [placeholder]="'Enter Description *'"
                      [style]="{ height: '320px' }"
                      (onContentChange)="onDescriptionChange($event)"
                      (onInit)="onEditorInit($event)">
                      <ng-template pTemplate="header">
                        <span class="ql-formats">
                          <button type="button" class="ql-bold" aria-label="Bold"></button>
                          <button type="button" class="ql-italic" aria-label="Italic"></button>
                          <button type="button" class="ql-underline" aria-label="Underline"></button>
                        </span>
                      </ng-template>
                    </p-editor>
                    <div class="error" *ngIf="addFlagReportForm.get('description')?.invalid && addFlagReportForm.get('description')?.touched">
                      Description is required.
                    </div>
                  </div>
                  <div class="add-query-input-content text-area desc">
                    <acengage-text-area [allowHTMLTags]="true" [placeholder]="'Enter Recommendation *'" formControlName="recommendation"></acengage-text-area>
                    <div class="error" *ngIf="addFlagReportForm.get('recommendation')?.invalid && addFlagReportForm.get('recommendation')?.touched">
                      Recommendation is required.
                    </div>
                  </div>
                </div>
                <div class="add-query-btn">
                  <div class="add-query-btn-cntr">
                    <div (click)="goBack.emit();report_type=''; this.addFlagReportForm.reset();">
                      <img src="shared-assets/images/close-bl.svg">
                      <span>Cancel</span>
                    </div>
                  </div>
                  <div class="add-query-btn-cntr">
                    <acengage-button buttonText="Submit" (buttonClicked)="flagReportSubmit()"
                      [isBtnDisable]="false">
                      <img src="shared-assets/images/general_icons/add_bg_dark.svg" alt="icon" class="img" />
                    </acengage-button>
                  </div>
                </div>
              </form>
            </div>
        </div>
        <!-- <acengage-button
                        [buttonText]="'Submit'"
                        [isBtnDisable]="addFlagReportForm.invalid"
                        (buttonClicked)="flagReportSubmit()"
                    >
                    </acengage-button>
                    <button type="button" (click)="flagReportSubmit()">Submit</button>
                    <button (click)="flagReportSubmit()" #myButton pButton class="width100 primary-btn" [disabled]="addFlagReportForm.invalid"
                        [type]="'button'">
                        Submit
                    </button> -->
    </div>
    <acengage-dialog
  [openModel]="showConfirmationDialog"
  [headerText]="''"
  [confirmButtonText]="'Yes'"
  [cancelButtonText]="'No'"
  (confirmButton)="submitFlagReport(false)"
  (cancelButton)="cancelPop()"
>
<div class="error-content">
  <p class="color-red"><i class="pi pi-times-circle" style="font-size: 2.5rem"></i></p>
  <div class="message-content">
    <p style="text-wrap: auto;">{{ confimationMessage }}</p>
  </div>
</div>
</acengage-dialog>
    
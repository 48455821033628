import { QuestionCheckboxTypeForm } from '@acengage/app/app/question-bank/models/add-question-bank.model';
import { AnswersNodeResponse } from '@acengage/app/app/question-bank/models/question-details.model';
import { CommonService } from '@acengage/app/app/services/common.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'acengage-nested-choice-question',
  templateUrl: './nested-choice-question.component.html',
  styleUrls: ['./nested-choice-question.component.scss'],
})
export class NestedChoiceQuestionComponent implements OnInit {
  @Input() questionForm: FormGroup;
  @Output() addCheckboxOptionClicked = new EventEmitter<null>();
  @Output() removeCheckboxOptionClicked = new EventEmitter<number>();
  @Output() addFollowUpClicked = new EventEmitter<number>();
  @Output() addClicked = new EventEmitter<null>();
  @Output() removeClicked = new EventEmitter<number>();
  @Output() addFollowUp = new EventEmitter<number>();
  @Input() isMultiChoice = false;
  @Output() updateNestedQuestions = new EventEmitter<any>();
  @Input() nestedQuestions: FormGroup;
  @Input() nestIndex: number = 0;
  constructor(
    private fb: FormBuilder,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.createNestedForm();
    this.questionForm.valueChanges.subscribe((res)=>{
      this.updateNestedQuestions.emit(this.questionForm);
    })
  }

  createNestedForm() {
    const existVal = this.nestedQuestions.value?.options[this.nestIndex]?.nested_question;
    this.questionForm = this.fb.group({
      question: this.fb.control(existVal.question ? existVal.question : '', [Validators.required]),
      options: this.createCheckBoxAnswer(existVal),
    })
  }

  
  createCheckBoxAnswer(presetVal?: any) {
    const checkboxAnswerFA: FormArray<FormGroup<QuestionCheckboxTypeForm>> = this.fb.array<FormGroup<QuestionCheckboxTypeForm>>([]);
    if (presetVal?.options.length) {
      presetVal?.options?.forEach((eachNodes: AnswersNodeResponse) => {
        checkboxAnswerFA.push(this.newCheckboxAnswer(eachNodes));
      });
    } else {
      checkboxAnswerFA.push(this.newCheckboxAnswer());
      checkboxAnswerFA.push(this.newCheckboxAnswer());
    }
    return checkboxAnswerFA;
  }

  newCheckboxAnswer(nodeDtls?: AnswersNodeResponse) {
    return this.fb.group<QuestionCheckboxTypeForm>({
      // , this.questionDtls?.nodes?.length ? [Validators.required] : []
      choice: this.fb.control(nodeDtls?.choice ? nodeDtls.choice : ''),
      // nested_question: this.createNestedQuestion()
    });
  }

  checkboxAnswerFA() {
    return this.questionForm.get('options') as FormArray<FormGroup<QuestionCheckboxTypeForm>>;
  }

  addMoreOptions() {
    this.checkboxAnswerFA().push(this.newCheckboxAnswer());
    this.checkboxAnswerFA()?.updateValueAndValidity();
    this.questionForm.updateValueAndValidity();
  }

  removeOptions(selectedIndex:any) {
    this.checkboxAnswerFA().removeAt(selectedIndex);
    this.checkboxAnswerFA()?.updateValueAndValidity();
  }
}

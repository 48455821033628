/**
 * Define application level constants that don't change based on environments
 */

export class Config {
  /**
   * @description Password Policy for the application.
   * Used in Registration, Forgot password and reset password.
   */
  static readonly passwordPolicy =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;
  static readonly emailPolicy = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  static readonly numberPolicy = /^(0|[1-9]\d*)?$/;
  static readonly decimalRegex = /^\d+(\.\d{1,2})?$/;
  static readonly amountRegex = /^\d*\.?\d*$/;
  static readonly amountRegex1 = /^\d*\.?\d{0,3}$/;
  static readonly usernameRegex = /^[a-zA-Z0-9]*[._]?[a-zA-Z0-9]*$/;
  static readonly usernameOrEmailRegex = /^(?:[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)?|[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  static readonly addressRegex = /^[a-zA-Z0-9]+[a-zA-Z0-9 ]*$/;
  static readonly alphanumWithoutSpace = /^[a-z0-9]+$/i;
  static readonly acceptExceptSpecialCharRegex = /^[a-zA-Z0-9_ ]*$/;
  static readonly mobileNumberRegex = /^\+[1-9]{1}[0-9]{6,14}$/;
  static readonly onlyAlphabetsAndSpace = /^[a-zA-Z]*[/ a-zA-Z]*$/;
  static readonly urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  /**
   * @description LoggedIn user token, You can change it for your need,
   * the below value will be stored in localstorage as a key for the token
   */
  static readonly userToken: string = 'token';
}

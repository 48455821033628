import {
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
  EventEmitter,
  Output
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ValueAccessorService } from '../../shared-service/valueAccessor.service';

@Component({
  selector: 'acengage-radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioBtnComponent),
      multi: true,
    },
  ],
})
export class RadioBtnComponent extends ValueAccessorService implements OnInit {
  @Input() options: any;
  @Input() followUp:boolean = false;
  @Input() map:boolean = false;
  @Input() override disabled: boolean = false;
  @Input() question:any;
  @Output() optionChange = new EventEmitter();
  constructor(injector: Injector) {
    super();
  }

  ngOnInit() { }
  changeValue(item:any,j:number){
    var data = {
      item:item,
      index:j
    }
   this.optionChange.emit(data) 
  }

}

<form [formGroup]="_questionForm">
    <div class="select__services--container">
        <div class="select__services--contents">
            <div class="select__services--row">
                <div class="select__services--select">
                    <acengage-select [options]="servicesList" [isMultiSelect]="true" [placeHolder]="'Select services *'"
                        formControlName="services" (ngModelChange)="getQuesIntervensions($event);">
                    </acengage-select>
                    <acengage-form-error [control]="_questionForm.controls.services" validatorName="required">
                        Services is required.
                    </acengage-form-error>
                </div>
                <div *ngIf="interventionsList.length && _questionForm.value?.services?.length"
                    class="select__services--select">
                    <acengage-select [options]="interventionsList" [isMultiSelect]="true" [placeHolder]="selectboxLabel"
                        formControlName="interventions" (ngModelChange)="acengageTreeForm.subservice = $event;">
                    </acengage-select>
                </div>
            </div>
            <div class="select__services--row" *ngIf="_questionForm.value?.services?.length">
                <div class="select__services--select">
                    <acengage-checkbox-input [cbLabel]="'Add to Standard Questionnaire'"
                        formControlName="addToQuestionnaire" [isNeedOutput]="true"
                        (checkboxClicked)="addToQuesValueChange($event);">
                    </acengage-checkbox-input>
                </div>
                <div class="select__services--select" *ngIf="_questionForm.value?.addToQuestionnaire">
                    <acengage-select [options]="questionnaireList" [isMultiSelect]="true"
                        [placeHolder]="'Select questionnaire *'" formControlName="questionnaire">
                    </acengage-select>
                    <acengage-form-error [control]="_questionForm.controls.questionnaire" validatorName="required">
                        Questionnaire is required.
                    </acengage-form-error>
                </div>
            </div>
        </div>
    </div>
</form>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonAPIRequest } from '../models/common-api-request.model';
import { QuestionIntervensionsPayload, SaveQuestionPayload } from '../question-bank/models/add-question-bank.model';

@Injectable({
    providedIn: 'root'
})

export class QuestionsService {
    constructor(private http: HttpClient) { }

    getQuestionBankFilters() {
        return this.http.get(`${environment.api_url}/question/question_filters_heading`);
    }

    getAllQuestions(apiReqDtls: CommonAPIRequest, filterDetails: any) {
        return this.http.post(`${environment.api_url}/question/list_all?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&sort_order=${apiReqDtls.sort_order}`, filterDetails);
    }

    getQuestionServices() {
        return this.http.get(`${environment.api_url}/question/configurations/services`);
    }

    getQuestionIntervensions(fetchIntervensionsPayload: QuestionIntervensionsPayload) {
        return this.http.post(`${environment.api_url}/question/configurations/interventions`, fetchIntervensionsPayload);
    }

    getQuestionTypes() {
        return this.http.get(`${environment.api_url}/question/configurations/types`);
    }

    addQuestion(saveQuestionPayload: SaveQuestionPayload) {
        return this.http.post(`${environment.api_url}/question/upload`, saveQuestionPayload);
    }

    updtQuestion(questionID: number | null, saveQuestionPayload: SaveQuestionPayload) {
        return this.http.put(`${environment.api_url}/question/edit/${questionID}`, saveQuestionPayload);
    }

    getQuestionDtls(questionID: number | null) {
        return this.http.get(`${environment.api_url}/question/get_details/${questionID}`);
    }

    getQuestionnaireList(questionnaireListPayload: { services: number[] }) {
        return this.http.post(`${environment.api_url}/question/questionnaire/list_all`, questionnaireListPayload);
    }
};

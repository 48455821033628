<div class="intervention__menu--container">
    <div class="intervention__menu--item" [ngClass]="{'active': selectedIntervention === 0}"
        (click)="selectedIntervention = 0; interventionTabChanged.emit(selectedIntervention);">
        <div class="intervention__menu--detail">
            <div class="intervention__menu--icon">
                <img src="shared-assets/images/general_icons/info_fill_light.svg" alt="info">
            </div>
            <div class="intervention__menu--text">{{(ServiceType === 'NHE' ? 'Employee' : 'Candidate')}} Details</div>
        </div>
        <div class="intervention__menu--arrow">
            <img src="shared-assets/images/angle-right.svg" alt="arrow">
        </div>
    </div>
    <ng-container *ngFor="let eachIntervention of interventionsList">
        <div class="intervention__menu--item" [ngClass]="{'active': selectedIntervention === eachIntervention.id}"
            (click)="selectedIntervention = eachIntervention.id; interventionTabChanged.emit(selectedIntervention);">
            <div class="intervention__menu--detail">
                <div class="intervention__menu--icon" *ngIf="eachIntervention.id == -1 || eachIntervention.id == -3 || eachIntervention.id == -4">
                    <img src="shared-assets/images/general_icons/info_fill_light.svg" alt="info">
                </div>
                <div class="intervention__menu--icon" *ngIf="eachIntervention.id !== -1 && eachIntervention.id !== -3 && eachIntervention.id !== -4">
                    <img src="shared-assets/images/general_icons/call_fill_light.svg" alt="call">
                </div>
                <div *ngIf="eachIntervention?.is_follow_up" class="intervention__menu--text">{{eachIntervention.name}}
                </div>
                <div *ngIf="!eachIntervention?.is_follow_up" class="intervention__menu--text">{{(eachIntervention.day ?
                    'Day ' + eachIntervention.day + ' - ' : '') + eachIntervention.name}}
                </div>
            </div>
            <div class="intervention__menu--arrow">
                <img src="shared-assets/images/angle-right.svg" alt="arrow">
            </div>
        </div>
    </ng-container>
    <!-- <div class="intervention__menu--item"  [ngClass]="{'active': selectedIntervention === -3}"
        (click)="selectedIntervention = -3; interventionTabChanged.emit(selectedIntervention);">
        <div class="intervention__menu--detail">
            <div class="intervention__menu--icon">
                <img src="shared-assets/images/general_icons/info_fill_light.svg" alt="continuity">
            </div>
            <div class="intervention__menu--text">Buddy Induction</div>
        </div>
        <div class="intervention__menu--arrow">
            <img src="shared-assets/images/angle-right.svg" alt="arrow">
        </div>
    </div> -->
    <div class="intervention__menu--item" [ngClass]="{'active': selectedIntervention === -2}"
        (click)="selectedIntervention = -2; interventionTabChanged.emit(selectedIntervention);">
        <div class="intervention__menu--detail">
            <div class="intervention__menu--icon">
                <img src="shared-assets/images/general_icons/info_fill_light.svg" alt="continuity">
            </div>
            <div class="intervention__menu--text">Ngage Continuity</div>
        </div>
        <div class="intervention__menu--arrow">
            <img src="shared-assets/images/angle-right.svg" alt="arrow">
        </div>
    </div>
</div>
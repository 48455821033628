<div class="container">
  <h1>Multiple Upload</h1>

  <acengage-fileupload
    (uploadedData)="fileUpload($event)"
    [acceptType]="'.csv,.xml,.pdf'"
    [name]="'Multi upload'"
  ></acengage-fileupload>

  <h1>Single Upload</h1>

  <acengage-fileupload
    [isMultiUpload]="false"
    [name]="'single upload'"
    (uploadedData)="fileUpload($event)"
  ></acengage-fileupload>
  <h1>Template Driven form</h1>

  <acengage-text-input
    [(ngModel)]="firstName"
    (ngModelChange)="onUpdate($event)"
  ></acengage-text-input>

  <h1>Reactive Form control</h1>

  <form [formGroup]="profileForm">
    <div>
      <label for="first-name">First Name: </label>
      <acengage-text-input
        formControlName="firstName"
        (ngModelChange)="onUpdate($event)"
      ></acengage-text-input>
    </div>
    <div *ngIf="profileForm.get('firstName')?.getError">reqi</div>
    <div>
      <label for="last-name">Last Name: </label>
      <input
        id="last-name"
        type="text"
        formControlName="lastName"
        (ngModelChange)="onUpdate($event)"
      />
    </div>
    <label>Gender</label>
    <acengage-radio-btn
      [options]="genderRadioOptions"
      formControlName="gender"
      (ngModelChange)="onUpdate($event)"
    ></acengage-radio-btn>
    <label>Country</label>
    <acengage-radio-btn
      [options]="countryOptions"
      formControlName="country"
      (ngModelChange)="onUpdate($event)"
    ></acengage-radio-btn>

    <label>Age</label>
    <acengage-checkbox-input
      [options]="ageOptions"
      formControlName="age"
      (ngModelChange)="onUpdate($event)"
    ></acengage-checkbox-input>

    <label>Single Select</label>
    <acengage-select [options]="ageSelectOptions" aria-placeholder="single select"></acengage-select>
    <label>Multi Select</label>
    <acengage-select
      [options]="ageSelectOptions"
      [isMultiSelect]="true"
    ></acengage-select>
    <button type="button" (click)="onSubmit()">Submit</button>
  </form>

  <h1>Tabs</h1>
  <acengage-tabs></acengage-tabs>

  <h1>Toaster</h1>
  <acengage-toaster toasterPosition="top-right" [toasterInfos]="toasterInfoObj" [displaytoaster]="displayToast" ></acengage-toaster>
  <button (click)="displayToast=true">Display Toaster</button>

  <h1>Loader</h1>
  <acengage-loader></acengage-loader>


  <h1>Dialog</h1>
  <button (click)="openModelDialog()">Open Dialog</button>
  <acengage-dialog [openModel]="openModel">
    <p>Model Content</p>
  </acengage-dialog>
</div>

<h1>edit table</h1>
<acengage-editable-table></acengage-editable-table>

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'acengage-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {
  isShowLoader: boolean = false;
  @Input() set showLoader(value: any) {
    this.isShowLoader = value;
  }
  constructor() { }

  ngOnInit(): void { }
}

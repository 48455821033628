import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-dummy-component',
  templateUrl: './dummy-component.component.html',
  styleUrls: ['./dummy-component.component.scss'],
})
export class DummyComponentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

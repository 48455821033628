import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CECampaignService } from '../services-screens/services/ce-campaign.service';
import { AppState } from '../state/app.state';
import * as AppActions from '../state/app.actions';
import { ActivatedRoute } from '@angular/router';
import { FlagReportFieldDetails, SlotChangeForm, ChangeSlotPayload } from '../services-screens/candidate-engagement/models/CE-Intervention-Slot-Selection.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { SelectOption } from '../models/select.model';
import { Config } from '../config';

@Component({
  selector: 'acengage-flag-report-form',
  templateUrl: './flag-report-form.component.html',
  styleUrls: ['./flag-report-form.component.scss'],
})
export class FlagReportFormComponent implements OnInit {
  constructor(private campaignCEService: CECampaignService,
    private appStore: Store<AppState>,
    private route: ActivatedRoute,
    private fb: FormBuilder,) { }

  id: number | null;
  key: string | null;
  todayDate: Date = new Date();
  flagReportForm: FormGroup<any>;
  // flagReportDetails = {} as FlagReportFieldDetails;
  flagReportDetails:any = {};
  slotSuccess = false;

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') ? Number(this.route.snapshot.paramMap.get('id')) : null;
    this.key = this.route.snapshot.paramMap.get('key') ? this.route.snapshot.paramMap.get('key') : null;
    this.getFlagDetails();
    // this.createflagReportForm();
  }

  createflagReportForm() {
    this.flagReportForm = this.fb.group({
      notes: this.fb.control('', [Validators.required]),
      resolution: this.fb.control('', [Validators.required]),
    });
    
  }
  updateflagReportForm() {
    this.flagReportForm = this.fb.group({
      notes: this.fb.control(this.flagReportDetails.notes, []),
      resolution: this.fb.control(this.flagReportDetails.resolution, []),
    });
    
  }
  getFlagDetails() {
    if (this.id && this.key) {
      this.showLoaderTrigger(true);
      this.campaignCEService.getFlagReportDetails(this.id,this.key).subscribe((flagReport: any) => {
        this.showLoaderTrigger(false);
        this.flagReportDetails = flagReport?.data || {};
        this.flagReportDetails?.is_active?this.createflagReportForm():this.updateflagReportForm();
        this.flagReportForm.patchValue({
          notes: flagReport?.data.notes,
          resolution: flagReport?.data.resolution
        });
      });
    }
  }

  submitFlagReport(){
    if (this.id && this.key) {
      this.showLoaderTrigger(true);
      let selectedSlot: any = {}
      const slotRawData = this.flagReportForm.getRawValue();
        selectedSlot = {
          notes:slotRawData.notes,
          resolution:slotRawData.resolution,
        }
      //console.log('selectedSlot manager',selectedSlot);
      this.campaignCEService.updateFlagReport(this.id,this.key, selectedSlot).subscribe((updtFlagResp: any) => {
        this.showLoaderTrigger(false);
        this.showToaster('success', '', updtFlagResp.message);
        this.flagReportDetails.is_submitted = true;
        this.flagReportForm.reset();
      });
    }
  }

  showToaster(type: string, heading: string, message: string) {
    this.appStore.dispatch(AppActions.showToaster({
      class: type,
      header: heading,
      message: message,
      showToaster: true
    }));
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}

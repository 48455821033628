<ngx-doc-viewer
  *ngIf="url"
  [url]="url"
  viewer="google"
  class="viewer-container"
  (loaded)="contentLoaded()"
>
  <div>Loading</div>
</ngx-doc-viewer>
<!-- <pdf-viewer></pdf-viewer> -->
<!-- <div class="pdf-viewer">
	<div id="pspdfkit-container" style="width: 100%; height: 100vh"></div>
</div> -->

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@acengage/app/app/state/app.state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginInformation } from '@acengage/app/app/authentication/models/login.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getLoginStates,
  getLoginError,
} from '@acengage/app/app/authentication/state/auth.selectors';
import { LoginState } from '@acengage/app/app/authentication/state/auth.reducer';
import * as AuthActions from '@acengage/app/app/authentication/state/auth.actions';
import { validationService } from '@acengage/app/app/services/validation.service';
import { Observable, tap } from 'rxjs';
import * as appActions from '@acengage/app/app/state/app.actions';

@Component({
  selector: 'acengage-client-login',
  templateUrl: './client-login.component.html',
  styleUrls: ['./client-login.component.scss'],
})
export class ClientLoginComponent implements OnInit {
  @Output() nextStep = new EventEmitter();
  showLoader = false;

  constructor(
    private store: Store<AppState>,
    private store1: Store<LoginState>,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public _vldService: validationService
  ) {}
  get loginFormControls(): any {
    return this.loginForm.controls;
  }
  step = 1;
  localStoreValues: any = { appStates: {} };
  loginForm!: FormGroup<LoginInformation>;
  showGoogleCode = false;
  qrText = '';

  loginStoreValue$: Observable<any>;
  toasterInfoObj = {
    severity: 'success',
    summary: '',
    detail: '',
  };

  displayToast = false;
  confimationMessage = '';
  showConfirmationDialog = false;
  isRouteChange = false;
  ngOnInit() {
    this.loginStoreValue$ = this.store1.select(getLoginStates).pipe(
      tap((state) => {
        this.localStoreValues = { appStates: state };
      })
    );
    if (localStorage.getItem('login')) {
      if (
        !this.localStoreValues.appStates.default_password_changed &&
        !this.showGoogleCode
      ) {
        this.step = 2;
      } else if (
        this.localStoreValues.appStates.is_2fa &&
        !this.showGoogleCode
      ) {
        this.step = 3;
      } else {
        this.router.navigateByUrl('/');
      }
    }
    this.route.queryParams.subscribe((params) => {
      this.showGoogleCode = params['fromEmail'] == 'true' ? false : true;
    });
    this.store1.select(getLoginError).subscribe((error: any) => {
      if (
        error &&
        error?.hasOwnProperty('error') &&
        error['error']?.hasOwnProperty('message') &&
        (error['error']['message'] == 'OTP entered is invalid' ||
          error['error']['message'] == 'Invalid OTP token')
      ) {
        this.loginForm.patchValue({
          otp: '',
        });
      }
    });
    this.createLoginForm();
  }
  createLoginForm(): void {
    this.loginForm = this.fb.group({
      username: [
        '',
        [
          Validators.required,
          this._vldService.MinMax(1, 50),
          this._vldService.validateSpace(),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          this._vldService.MinMax(1, 50),
          this._vldService.validateSpace(),
        ],
      ],
      otp: ['', []],
    });
    if (this.showGoogleCode) {
      // this.loginForm.controls.otp.setValidators([
      //   Validators.required,
      //   this._vldService.MinMax(1, 7),
      //   this._vldService.validateSpace(),
      // ]);
    }
  }

  login() {
    if (!this.loginForm.valid) {
      return;
    }
    const body = {
      username:
        (this.loginForm.controls['username']?.value?.toLowerCase() as any) ||
        '',
      password: this.loginForm.controls['password'].value as any,
      otp: this.loginForm.controls['otp']?.value as any,
    };

    if (this.showGoogleCode) {
      this.store.dispatch(appActions.showLoader({ showLoader: true }));
      this.store1.dispatch(AuthActions.LoginRequest({ payload: body }));
    } else {
      delete body.otp;
      this.store.dispatch(appActions.showLoader({ showLoader: true }));
      this.store1.dispatch(AuthActions.firstLoginRequest({ payload: body }));
    }
    this.showLoader = true;
    this.displayToast = false;
  }

  placeHolderForPassword() {
    return this.showGoogleCode ? 'Password' : 'Password that send to your mail';
  }
  getLoginBtnText() {
    return this.showGoogleCode ? 'Login' : 'Next';
  }
  checkForEnter(event: any) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-slot-selection-success',
  templateUrl: './slot-selection-success.component.html',
  styleUrls: ['./slot-selection-success.component.scss'],
})
export class SlotSelectionSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

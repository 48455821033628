import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appDisableInteraction]'
})
export class DisableInteractionDirective implements OnInit {
  @Input() isCounsellor: boolean; // isLoggedInAsCounsellor flag
  @Input() isQuality: boolean; // Quality flag input

  constructor(private el: ElementRef) {}

  ngOnInit() {
    // Check conditions and disable interaction if needed
    if (this.isCounsellor && this.isQuality) {
      this.disableInteraction();
    }
  }

  private disableInteraction() {
    const element = this.el.nativeElement;

    // Disable clicks and input
    element.style.pointerEvents = 'none'; // Disable all pointer events
    element.setAttribute('disabled', 'true'); // Disable if it's a form element

    // Optionally, you can add a class to visually indicate it's disabled
    element.classList.add('disabled'); // Add a 'disabled' class for styling
  }
}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-text-input-mask',
  templateUrl: './text-input-mask.component.html',
  styleUrls: ['./text-input-mask.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputMaskComponent),
      multi: true,
    },
  ],
})
export class TextInputMaskComponent implements ControlValueAccessor {
  constructor() {}
  @Input() id = '';
  @Input() placeholder = '';
  @Input() inputType = 'text';
  @Input() isShowFloatLabel = true;
  @Input() mask = '99 99 99 99 99';
  @Input() unmask = false;
  @Input() slotChar = '_';
  value: any;
  disabled = false;
  private valueChanges = new Subject<string>();
  private touches = new Subject();
  onChange = () => {};
  onTouch = () => {};

  handleEmptySpace(event: any) {
    if (event.which === 32 && !this.value.length) {
      event.preventDefault();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value ? value.trim() : '';
  }
  updateValue(value: any) {
    this.value = value ? value.trim() : '';
    this.valueChanges.next(this.value);
  }
  touch() {
    this.touches.next(null);
  }
}

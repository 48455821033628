<div class="select__services--container">
    <div class="select__services--contents">
        <div class="select__services--select">
            <acengage-text-input [placeholder]="'Tree Heading *'" [disabled]="processType === 'view'" [name]="'heading'"
                [(ngModel)]="treeForm.heading" #heading="ngModel" [required]="true">
            </acengage-text-input>
            <div *ngIf="heading.invalid && (heading.touched || heading.dirty)" class="form-error-message">
                Tree heading is required.
            </div>
        </div>
        <div class="select__services--select">
            <acengage-select [options]="servicesList" [disabled]="!!processType" [isMultiSelect]="false"
                [placeHolder]="'Select Service *'" [name]="'service'" [(ngModel)]="treeForm.service" #service="ngModel"
                (ngModelChange)="treeForm.options = []; getSubServiceList($event); fetchMappingFields();"
                [required]="true">
            </acengage-select>
            <div *ngIf="service.invalid && (service.touched || service.dirty)" class="form-error-message">
                Service is required.
            </div>
        </div>
        <div [hidden]="getServiceUID() !== 'CE'" class="select__services--select">
            <acengage-select [options]="subServicesList" [disabled]="!!processType" [isMultiSelect]="false"
                [placeHolder]="'Select Sub-Category *'" [name]="'subservice'" [(ngModel)]="treeForm.subservice"
                #subservice="ngModel" [required]="getServiceUID() === 'CE'"
                (ngModelChange)="treeForm.options = []; fetchMappingFields();">
            </acengage-select>
            <div *ngIf="subservice.invalid && (subservice.touched || subservice.dirty)" class="form-error-message">
                Sub-Category is required.
            </div>
        </div>
        <div class="select__services--btn--container" *ngIf="processType !== 'view'">
            <acengage-button buttonText="Add" [isBtnDisable]="heading.invalid || service.invalid || subservice.invalid"
                (buttonClicked)="addClicked.emit();">
            </acengage-button>
            <div *ngIf="treeForm.options.length" class="select__services--btn--link" (click)="resetClicked.emit();">
                Reset</div>
        </div>
    </div>
</div>
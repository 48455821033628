import { CommonService } from 'apps/acengage/src/app/services/common.service';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, mergeMap, tap, distinctUntilChanged } from 'rxjs/operators';
import { of } from 'rxjs';
import { fetch } from '@nrwl/angular';
import { MenuPayload } from './app.model';

import * as appActions from './app.actions'
import { Store } from '@ngrx/store';
@Injectable()
export class AppEffects {
  constructor(
    private readonly actions$: Actions,
    private commonService: CommonService,
    private store: Store,
  ) { }


  // sideMenuBar$ = createEffect(() => {
  //   console.log('sidemenu called from app')
  //   return this.actions$.pipe(
  //     ofType(appActions.sideMenuBar),
  //     mergeMap((action) => {
  //       return this.commonService.getSideMenu().pipe(
  //         map((response: any) => {
  //           this.store.dispatch(appActions.showLoader({ showLoader: false }));
  //           return appActions.sideMenuBarSuccess({ menuItem: response.data });
  //         }),
  //         catchError((error: any) => {
  //           this.store.dispatch(appActions.showLoader({ showLoader: false }));
  //           this.store.dispatch(appActions.showToaster({
  //             class: 'error',
  //             header: 'Error',
  //             message: error.error.message,
  //             showToaster: true
  //           }));
  //           return of(appActions.sideMenuBarFailure(error?.error?.message));
  //         })
  //       )
  //     })
  //   );
  // });

  selectSideMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appActions.selectedMenu),
      tap((res:any) => {
      })
    )
  }, { dispatch: false })
  selectSideSubMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appActions.selectedSubMenu),
      tap((res:any) => {
      })
    )
  }, { dispatch: false })
}
